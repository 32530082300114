import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { PrivacyComponent } from './pages/privacy-policy/privacy-policy.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthComponent } from './pages/auth/auth/auth.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { LogoutComponent } from './pages/auth/logout/logout.component';
import { ProfileOverviewComponent } from './pages/profile/profile.component';
import { DashboardAuthGuard } from './guards/dashboard-auth.guard';
import { MatchesComponent } from './pages/matches/matches.component';
import { MatchComponent } from './pages/match/match.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { ShopComponent } from './pages/shop/shop/shop.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "auth", component: AuthComponent },
  { path: "leaderboard", component: LeaderboardComponent },
  { path: "privacy-policy", component: PrivacyComponent },
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  {
    path: "profile",
    component: ProfileOverviewComponent,
    canActivate: [DashboardAuthGuard],
  },
  {
    path: "matches",
    component: MatchesComponent,
    canActivate: [DashboardAuthGuard],
  },

  {
    path: "matches/match/:id",
    component: MatchComponent,
    canActivate: [DashboardAuthGuard],
  },

  { path: "shop", component: ShopComponent },
  { path: "payment-success", component: PaymentSuccessComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: "enabledNonBlocking" }),
  ],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppRoutingModule { }
