import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
import { ShopService } from '../../../services/shop.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {

  get decorators() { return this.shopService.decorators; }

  constructor(public translate: TranslateService,
    private route: ActivatedRoute,
    private shopService: ShopService,
    public userService: UserService
  ) { }

  async ngOnInit() {
    await this.userService.init();
    await this.shopService.init();

    const status = this.route.snapshot.queryParamMap.get('payment_status');
    if (status === 'failed')
      alert('Payment Failed');
  }

  async checkout(decorator: string) {
    window.location.href = this.shopService.shopDecorator(decorator);
  }
}
