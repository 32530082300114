<sidebar>
  <div class="side-nav navbar-dark">
    <div class="header-profile rounded-4">
      <img
        width="100%"
        height="100%"
        class="rounded-4"
        [src]="BannerURL"
        loading="lazy"
        [alt]="user.username"
      />
      <img
        width="100%"
        height="100%"
        class="profile"
        [src]="savedUser.avatar"
        loading="lazy"
        [alt]="user.username"
      />
      <div class="navbar-nav nav-pills px-2">
        <div
          class="nav-item nav-link px-2 gap-2 cursor-pointer"
          data-bs-toggle="modal"
          data-bs-target="#user-card"
        >
          <svg
            width="24"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M472 22a56 56 0 0 0-80 0l-30 30 98 98 30-30c22-22 22-58 0-80l-18-18zM172 242c-6 6-10 13-13 22l-30 88a24 24 0 0 0 31 31l89-30c8-3 15-7 21-13l168-168-98-98-168 168zM96 64c-53 0-96 43-96 96v256c0 53 43 96 96 96h256c53 0 96-43 96-96v-96a32 32 0 1 0-64 0v96c0 18-14 32-32 32H96c-18 0-32-14-32-32V160c0-18 14-32 32-32h96a32 32 0 1 0 0-64H96z"
              fill="var(--font)"
            />
          </svg>
          {{ "DASHBOARD.SIDEBAR.EDIT" | translate }}
        </div>
      </div>
    </div>
    <div class="navbar-nav nav-pills px-2">
      <div class="my-2">
        <div class="category fw-bold text-uppercase">
          {{ "DASHBOARD.THEMES" | translate }}
        </div>
        <form #f="ngForm" class="px-2">
          <select
            class="p-2 w-100 border-0 rounded text-font bg-tertiary"
            [value]="service.currentTheme"
            [formControl]="themeControl"
            id="theme-select"
            name="theme-select"
            #themeSelect
            aria-label="select"
          >
            <option value="DEFAULT" selected>
              Default {{ "DASHBOARD.DEFAULT" | translate }}
            </option>
          </select>
        </form>
      </div>
      <div class="my-2">
        <div class="category fw-bold text-uppercase">
          {{ "DASHBOARD.SIDEBAR.TEAMS" | translate }}
        </div>
        <a
          class="nav-item nav-link d-flex align-items-center px-2 gap-2 cursor-pointer"
          data-bs-toggle="modal"
          data-bs-target="#create-team-card"
        >
          <svg
            width="24"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 512a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm-24-168v-64h-64a24 24 0 1 1 0-48h64v-64a24 24 0 1 1 48 0v64h64a24 24 0 1 1 0 48h-64v64a24 24 0 1 1-48 0z"
              fill="var(--font)"
            />
          </svg>
          {{ "TEAM.CREATE.TITLE" | translate }}
        </a>
        <!-- Lista de equipos -->
        <div *ngFor="let team of teams" class="w-100">
          <div
            class="nav-item nav-link d-flex align-items-center px-2 gap-2 cursor-pointer"
            (click)="
              openDialog(
                team._id,
                team.name,
                team.teamMembers,
                team.stats.earnings,
                team.stats.played,
                team.stats.wins,
                team.stats.losses
              )
            "
          >
            <svg
              width="24"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                d="M72 88a56 56 0 1 1 112 0 56 56 0 1 1-112 0zm-8 158a63 63 0 0 0 0 84v-84zm144-50a143 143 0 0 0-16 199v21c0 18-14 32-32 32H96c-18 0-32-14-32-32v-27a112 112 0 0 1 48-213h32c24 0 46 8 64 20zm240 220v-21a143 143 0 0 0-16-199c18-12 40-20 64-20h32a112 112 0 0 1 48 213v27c0 18-14 32-32 32h-64c-18 0-32-14-32-32zm8-328a56 56 0 1 1 112 0 56 56 0 1 1-112 0zm120 158v84a64 64 0 0 0 0-84zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm-80 272c0 16 6 31 16 42v-84a64 64 0 0 0-16 42zm144-42v84a64 64 0 0 0 0-84zm64 42c0 45-26 83-64 101v43c0 18-14 32-32 32h-64c-18 0-32-14-32-32v-43a112 112 0 0 1 48-213h32c62 0 112 50 112 112z"
                fill="var(--font)"
              /></svg
            ><span>{{ team.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="content">
    <ng-content></ng-content>
  </div>
</sidebar>

<div
  class="modal fade"
  id="create-team-card"
  tabindex="-1"
  role="dialog"
  aria-labelledby="create-team-card"
  aria-hidden="true"
>
  <create-team-card></create-team-card>
</div>

<div
  class="modal fade"
  id="user-card"
  tabindex="-1"
  role="dialog"
  aria-labelledby="user-card"
  aria-hidden="true"
>
  <user-card></user-card>
</div>
