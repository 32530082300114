import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.css'],
})
export class LeaderboardComponent {
  members: any[]; // Definir como array de cualquier tipo
  membersIds: any[]; // Definir como array de cualquier tipo
  memberId: any;

  constructor(
    public translate: TranslateService,
    public apiService: ApiService
  ) {
    document.title = 'BeatTheAura - Social Token Platform';
    this.members = []; // Inicializar como un array vacío
  }

  async ngOnInit() {
    this.membersIds = await this.apiService.getUsersIds();
    for (this.memberId of this.membersIds) {
      const user = await this.apiService.getUser(this.memberId);
      this.members.push(user.savedUser); // Agregar el objeto de usuario completo al array
    }
    this.members.sort((a, b) => b.coins - a.coins);
    console.log(this.members);
  }
}
