<div class="p-2">
  <h1>{{ data.teamSize }} Fortnite</h1>
  <hr>
  <div class="d-flex flex-column gap-4">
    <p>{{ "MATCH.DETAILS" | translate}}</p>
    <div class="d-flex flex-grow gap-2">
      <span class="cardFVB">
        {{ "MATCH.SIZE.TITLE" | translate}}: {{ data.teamSize }}
      </span>
      <span class="cardFVB">
        {{ "MATCH.MODE.TITLE" | translate}}: {{ data.mode }}
      </span>
      <span class="cardFVB">
        {{ "MATCH.BESTOF.TITLE" | translate}}: {{ data.bestOf }}
      </span>
      <span class="cardFVB">
        Coins: {{ data.coins }}
      </span>
    </div>
  </div>
  <hr>
  <div>
    <label>{{ "MATCH.SELECT.TITLE" | translate}}</label>
    <select [(ngModel)]="selectedTeam" required>
      <option *ngFor="let team of getFilteredTeams()" [ngValue]="team">
        {{ team.name }}
      </option>
    </select>
  </div>
  <hr>
  <button class="btn btn-primary" (click)="submitAccept(data._id)">{{ "MODAL.ACCEPT" | translate}}</button>
</div>