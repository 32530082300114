import { Component, OnInit, ViewChild } from '@angular/core';
import { User, UserService, WebSocketUser } from '../../services/user.service';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LangService } from '../../services/lang.service';
import { WebSocketService } from '../../services/websocket.service';
import { WebSocketEvents, WebSocketMessage } from '../../services/websocket.interface';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @ViewChild('langSelect') langSelect: MatSelect;

  navItems = [
    {
      svgHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32px" viewBox="1.001 14.744 510.1938 453.544"><path fill="var(--font)" d="M511.061 241.073c0 15.945-13.287 28.435-28.346 28.435h-28.347l.62 141.91c0 2.392-.177 4.783-.443 7.175v14.262c0 19.577-15.856 35.433-35.433 35.433h-14.173c-.974 0-1.949 0-2.923-.088-1.24.088-2.481.088-3.721.088H348.246c-19.577 0-35.433-15.856-35.433-35.433v-77.953c0-15.679-12.668-28.346-28.347-28.346h-56.693c-15.679 0-28.346 12.667-28.346 28.346v77.953c0 19.577-15.857 35.433-35.433 35.433h-49.518c-1.329 0-2.658-.088-3.987-.177-1.063.089-2.126.177-3.189.177H93.127c-19.577 0-35.433-15.856-35.433-35.433v-99.213c0-.797 0-1.683.089-2.48v-61.654H29.348c-15.945 0-28.347-12.401-28.347-28.435 0-7.972 2.657-15.059 8.858-21.26L236.986 21.83c6.201-6.2 13.287-7.086 19.488-7.086 6.201 0 13.287 1.771 18.602 6.201l226.241 198.868c7.087 6.201 10.63 13.288 9.744 21.26Z"/></svg>',
      label: 'Home',
      url: '/'
    },
    {
      svgHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32px" viewBox="16.0344 79.9988 479.9724 352.0511"><path fill="var(--font)" d="M483.13 245.38C461.92 149.49 430 98.31 382.65 84.33A107.13 107.13 0 0 0 352 80c-13.71 0-25.65 3.34-38.28 6.88C298.5 91.15 281.21 96 256 96s-42.51-4.84-57.76-9.11C185.6 83.34 173.67 80 160 80a115.74 115.74 0 0 0-31.73 4.32c-47.1 13.92-79 65.08-100.52 161C4.61 348.54 16 413.71 59.69 428.83a56.62 56.62 0 0 0 18.64 3.22c29.93 0 53.93-24.93 70.33-45.34 18.53-23.1 40.22-34.82 107.34-34.82 59.95 0 84.76 8.13 106.19 34.82 13.47 16.78 26.2 28.52 38.9 35.91 16.89 9.82 33.77 12 50.16 6.37 25.82-8.81 40.62-32.1 44-69.24 2.57-28.48-1.39-65.89-12.12-114.37zM208 240h-32v32a16 16 0 0 1-32 0v-32h-32a16 16 0 0 1 0-32h32v-32a16 16 0 0 1 32 0v32h32a16 16 0 0 1 0 32zm84 4a20 20 0 1 1 20-20 20 20 0 0 1-20 20zm44 44a20 20 0 1 1 20-19.95A20 20 0 0 1 336 288zm0-88a20 20 0 1 1 20-20 20 20 0 0 1-20 20zm44 44a20 20 0 1 1 20-20 20 20 0 0 1-20 20z"/></svg>',
      label: 'Matches',
      url: '/matches'
    },
    {
      svgHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32px" viewBox="1.001 22.356 510.194 445.382"><path fill="var(--font)" d="M355.302 22.356H156.894c-23.03 0-42.517 19.138-41.631 41.755l.886 13.919h-93.89c-11.515 0-21.258 9.567-21.258 20.877 0 80.899 30.115 136.573 69.974 174.845 38.973 37.406 86.804 55.674 122.234 65.243 20.373 6.089 34.544 23.487 34.544 40.015 0 18.268-15.057 33.054-33.658 33.054h-23.03c-21.819 0-35.456 23.198-24.546 41.755 5.063 8.613 14.42 13.919 24.546 13.919h170.066c21.819 0 35.456-23.197 24.546-41.755-5.063-8.612-14.42-13.919-24.546-13.919h-23.03c-18.601 0-33.658-14.786-33.658-33.054 0-16.528 14.171-33.926 34.544-39.145 35.43-9.569 83.261-28.707 123.12-66.113 39.858-38.272 69.088-93.946 69.088-174.845 0-11.31-9.743-20.877-21.258-20.877h-93.89l.886-13.919c.886-22.617-17.715-41.755-41.631-41.755ZM44.403 119.783h74.403c7.972 78.291 25.687 130.483 46.06 166.149-22.144-9.569-45.174-23.487-64.661-42.625-28.344-26.965-51.373-66.11-55.802-123.524Zm367.588 123.524c-19.487 19.138-42.517 33.056-64.661 42.625 20.373-35.666 38.088-87.858 46.06-166.149h74.403c-4.429 57.414-27.458 96.559-55.802 123.524Z"/></svg>',
      label: 'Leaderboard',
      url: '/leaderboard'
    },
    {
      svgHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32px" viewBox="1.001 43.889 510.194 424.108"><path fill="var(--premium)" d="m499.612 142.13-53.758-85.816c-4.805-7.704-13.336-12.425-22.449-12.425H88.758c-9.112 0-17.644 4.721-22.448 12.425L12.551 142.13c-27.749 44.315-3.148 105.944 48.706 112.984 3.728.498 7.537.746 11.348.746 24.519 0 46.222-10.768 61.131-27.417 14.911 16.649 36.696 27.417 61.131 27.417 24.52 0 46.222-10.768 61.132-27.417 14.91 16.649 36.695 27.417 61.131 27.417 24.519 0 46.221-10.768 61.131-27.417 14.993 16.649 36.695 27.417 61.131 27.417 3.893 0 7.621-.248 11.349-.746 52.019-6.957 76.704-68.586 48.871-112.984Zm-60.054 140.32c-8.284 0-16.484-1.243-24.436-3.148v82.668H97.041v-82.668c-7.952 1.823-16.152 3.148-24.436 3.148-4.969 0-10.023-.332-14.91-.994-4.639-.662-9.194-1.74-13.584-2.982V441.49c0 14.662 11.845 26.507 26.507 26.507h371.094c14.662 0 26.506-11.845 26.506-26.507V278.474c-4.473 1.325-8.946 2.402-13.584 2.982-5.053.662-10.023.994-15.076.994Z"/></svg>',
      label: 'Shop',
      url: '/shop'
    }
  ];

  OneUser: User = {
    _id: '',
    epicId: '',
    coins: 0,
    username: '',
    avatar: ''
  }

  environment = environment;
  defaultLang = 'es';

  form = new FormGroup({
    lang: new FormControl(localStorage.getItem('lang') ?? this.defaultLang),
    coins: new FormControl(0, [Validators.required, Validators.min(0.50)]),
  });

  get user() { return this.userService.user; }
  get savedUser() { return this.userService.savedUser; }

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private servicelang: LangService,
    public userService: UserService,
    private webSocketService: WebSocketService
  ) {
    this.servicelang.updateLang();
  }

  async ngOnInit() {
    this.webSocketService.subscribeToUpdates(WebSocketEvents.USER_UPDATED, { userId: this.user.id });
    this.webSocketService.listenForUpdates(WebSocketEvents.USER_UPDATED).subscribe({
      next: (message: WebSocketMessage) => {
        if (message.event === WebSocketEvents.USER_UPDATED) {
          this.OneUser = message.data.updatedUser;
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
    if (this.user !== undefined) {
      this.form.valueChanges.subscribe(() =>
        this.servicelang.changeLang(this.form.get('lang').value)
      );
      const status = this.route.snapshot.queryParamMap.get('payment_status');
      if (status === 'failed')
        alert('Payment Failed');
    }
  }
}