<navbar></navbar>
<div class="d-flex transition-all">
  <div class="mw-min-content">
    <button class="toggle p-2 mt-2 ms-2 bg-tertiary border border-1 rounded" (click)="toggle()">
      <svg
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6h2v12h-2zM4 13h8.586l-4.293 4.293 1.414 1.414L16.414 12 9.707 5.293 8.293 6.707 12.586 11H4z" style="fill: var(--font)"
        ></path>
      </svg>
    </button>
    <div
      class="sidenav rounded-5 border border-2"
      [ngClass]="{ side: true, opened: sidenavOpened }"
    >
      <button
        class="p-2 mt-2 ms-2 bg-tertiary border border-1 rounded d-flex align-items-center position-absolute"
        (click)="toggle()"
      >
        <svg
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6h2v12H4zm10.293-.707L7.586 12l6.707 6.707 1.414-1.414L11.414 13H20v-2h-8.586l4.293-4.293z" style="fill: var(--font)"
          ></path>
        </svg>
      </button>
      <div class="options">
        <ng-content select=".side-nav"></ng-content>
      </div>
    </div>
  </div>
  <div class="w-100"><ng-content select="#content"></ng-content></div>
</div>
