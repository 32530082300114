import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  sidenavOpened: boolean = true;
  isDrawerOpen: boolean = true;

  get user() { return this.userService.user || {}; }

  constructor(
    private userService: UserService,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  async ngOnInit() {
  }

  async toggle() {
    this.isDrawerOpen = !this.isDrawerOpen;

    const sidenav = this.el.nativeElement.querySelector('.sidenav');
    const toggle = this.el.nativeElement.querySelector('.toggle');

    if (this.isDrawerOpen) {
      // Lógica cuando el panel lateral está abierto
      this.renderer.setStyle(sidenav, 'display', 'block');
      this.renderer.setStyle(toggle, 'display', 'none');
    } else {
      // Lógica cuando el panel lateral está cerrado
      this.renderer.setStyle(sidenav, 'display', 'none');
      this.renderer.setStyle(toggle, 'display', 'block');
    }
  }
}