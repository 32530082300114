<div class="modal-dialog" role="document">
  <div class="modal-content">
    <form #f="ngForm" [formGroup]="form">
      <div class="modal-header">
        <h5 class="modal-title">{{ "MATCH.TITLE" | translate }}</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <label>{{ "MATCH.MODE.TITLE" | translate }}</label>
          <select
            class="p-2 w-100 border-0 rounded text-font bg-tertiary"
            formControlName="mode"
            required
          >
            <option *ngFor="let gamemode of gamemodes" [value]="gamemode.value">
              {{ gamemode.name }}
            </option>
          </select>
          <div *ngIf="form.invalid && form.get('mode')?.errors?.['required']">
            {{ "MATCH.MODE.ERROR" | translate }}
          </div>
        </div>
        <div>
          <label>{{ "MATCH.BESTOF.TITLE" | translate }}</label>
          <select
            class="p-2 w-100 border-0 rounded text-font bg-tertiary"
            formControlName="bestOf"
            required
          >
            <option *ngFor="let bestof of bestofs" [value]="bestof.value">
              {{ bestof.name }}
            </option>
          </select>
          <div *ngIf="form.invalid && form.get('bestOf')?.errors?.['required']">
            {{ "MATCH.BESTOF.ERROR" | translate }}
          </div>
        </div>
        <div>
          <label>{{ "MATCH.SIZE.TITLE" | translate }}</label>
          <select
            class="p-2 w-100 border-0 rounded text-font bg-tertiary"
            formControlName="teamSize"
            required
            (change)="filterTeams($event)"
          >
            <option *ngFor="let teamsize of teamsizes" [value]="teamsize.value">
              {{ teamsize.name }}
            </option>
          </select>
          <div
            *ngIf="form.invalid && form.get('teamSize')?.errors?.['required']"
          >
            {{ "MATCH.SIZE.ERROR" | translate }}
          </div>
        </div>
        <div formGroupName="teamHost">
					<label>{{ "MATCH.SELECT.TITLE" | translate}}</label>
					<mat-select class="p-2 w-100 border-0 rounded text-font bg-tertiary" (selectionChange)="onTeamSelectionChange($event.value)">
						<mat-option *ngFor="let team of teams" [value]="team">
							{{team.name}}
						</mat-option>
					</mat-select>
				</div>
        <div>
          <label>{{ "MATCH.COINS.TITLE" | translate }}</label>
          <div class="d-flex align-items-center justify-content-center gap-2">
            <input
              class="p-2 w-80 border border-0 rounded text-font bg-tertiary"
              min="0.50"
              max="10000.00"
              step="0.01"
              [formControl]="coinstest"
              #incoins
              type="number"
              required
            />
            <div
              class="w-20 p-2 w-max-content bg-success-subtle rounded"
              role="alert"
            >
              <span
                class="d-flex flex-row align-items-center justify-content-center gap-2"
                >{{ amount(form.value.coins) | number : "1.2-2"
                }}<img
                  src="assets/img/Coin.svg"
                  alt="BeatTheAura"
                  width="24px"
                  height="24px"
                  class="mw-100 rounded-5"
                  style="aspect-ratio: 1/1"
              /></span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{ "MODAL.CLOSE" | translate }}
        </button>
        <button
          [disabled]="form.invalid"
          type="button"
          class="btn btn-bd-primary"
          data-bs-dismiss="modal"
          (click)="submitMatch(f.value)"
        >
          {{ "MATCH.TITLE" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
