<nav
  class="navbar navbar-expand-sm shadow-sm"
  style="background-color: var(--background-tertiary)"
>
  <div class="container-fluid">
    <div class="d-flex align-items-center gap-2 cursor-pointer" routerLink="/">
      <img
        src="assets/img/logo.webp"
        alt="BeatTheAura"
        width="128px"
        class="mw-100"
      />
    </div>
    <ul class="navbar-nav align-items-center gap-3">
      <li *ngFor="let link of navItems" class="d-none d-xxl-block nav-item">
        <a
          class="nav-link p-2"
          [routerLink]="link.url"
          routerLinkActive="active"
        >
          <div [innerHTML]="link.svgHtml | safeHtml"></div>
        </a>
      </li>
      <div *ngIf="!user">
        <div class="dropdown">
          <button
            class="rounded-5 border border-0 p-2"
            style="background-color: var(--button-primary)"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div
              class="d-flex align-items-center"
              style="width: 24px; height: 24px"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                viewBox="0 0 448 512"
              >
                <path
                  d="M224 256a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3 0 498.7 13.3 512 29.7 512h388.6c16.4 0 29.7-13.3 29.7-29.7 0-98.5-79.8-178.3-178.3-178.3h-91.4z"
                  style="fill: var(--font)"
                />
              </svg>
            </div>
          </button>
          <ul
            class="dropdown-menu dropdown-menu-end rounded-4 p-2 position-absolute"
            aria-labelledby="dropdownMenuButton"
          >
            <form #f="ngForm" [formGroup]="form">
              <select
                [value]="translate.currentLang"
                class="p-2 w-100 border-0 rounded text-font bg-tertiary"
                formControlName="lang"
                id="language-select"
                name="language-select"
                #langselect
                (change)="translate.use(langselect.value)"
                aria-label="select"
              >
                <option value="es">Español</option>
                <option value="en">English</option>
                <option value="fr">Français</option>
              </select>
            </form>
            <a class="dropdown-item" routerLink="/login">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                viewBox="0 0 448 512"
              >
                <path
                  d="M224 256a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3 0 498.7 13.3 512 29.7 512h388.6c16.4 0 29.7-13.3 29.7-29.7 0-98.5-79.8-178.3-178.3-178.3h-91.4z"
                  style="fill: var(--font)"
                />
              </svg>
              {{ "DASHBOARD.SESSION" | translate }}
            </a>
          </ul>
        </div>
      </div>
      <div *ngIf="user">
        <div class="dropdown">
          <button
            class="dropdown-toggle rounded-5 border border-0"
            type="button"
            style="background: var(--background-tertiary)"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-auto-close="outside"
          >
            <img
              [src]="savedUser?.avatar"
              loading="auto"
              alt="{{ user.name }}"
              width="32px"
              height="32px"
              class="mw-100 rounded-5"
              style="aspect-ratio: 1/1"
            />
            <span
              class="position-absolute top-100 start-100 translate-middle shadow-sm badge rounded-pill btn-bd-primary"
              >{{ OneUser.coins | number : "1.2-2" }}</span
            >
          </button>
          <ul
            class="dropdown-menu dropdown-menu-end rounded-4 p-2 position-absolute"
            aria-labelledby="dropdownMenuButton"
          >
            <form #f="ngForm" [formGroup]="form">
              <select
                [value]="translate.currentLang"
                class="p-2 w-100 border-0 rounded text-font bg-tertiary"
                formControlName="lang"
                id="language-select"
                name="language-select"
                #langselect
                (change)="translate.use(langselect.value)"
                aria-label="select"
              >
                <option value="es">Español</option>
                <option value="en">English</option>
                <option value="fr">Français</option>
              </select>
            </form>
            <a
              class="dropdown-item"
              routerLink="/profile"
              routerLinkActive="active"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 512 512"
              >
                <path
                  d="M495.9 166.6c3.2 8.7.5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4l-55.6 17.8c-8.8 2.8-18.6.3-24.5-6.8-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4c-1.1-8.4-1.7-16.9-1.7-25.5s.6-17.1 1.7-25.4l-43.3-39.4c-6.9-6.2-9.6-15.9-6.4-24.6 4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2 5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8 8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
                  style="fill: var(--font)"
                />
              </svg>
              {{ "DASHBOARD.PROFILE" | translate }}</a
            >
            <a class="dropdown-item" routerLink="/logout">
              <svg
                viewBox="0 0 640 512"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M624 448h-80V113.45C544 86.19 522.47 64 496 64H384v64h96v384h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM312.24 1.01l-192 49.74C105.99 54.44 96 67.7 96 82.92V448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h336V33.18c0-21.58-19.56-37.41-39.76-32.17zM264 288c-13.25 0-24-14.33-24-32s10.75-32 24-32 24 14.33 24 32-10.75 32-24 32z"
                  style="fill: var(--font)"
                ></path>
              </svg>
              {{ "DASHBOARD.LOGOUT" | translate }}</a
            >
          </ul>
        </div>
      </div>
    </ul>
  </div>
</nav>

<div
  class="d-block d-xxl-none position-fixed bottom-0 left-0 z-3 w-100 h-16 border border-0"
  style="background-color: #00000080; backdrop-filter: blur(40px)"
>
  <div class="grid h-100 grid-cols-4 mx-auto">
    <button
      *ngFor="let link of navItems"
      type="button"
      class="d-inline-flex flex-column align-items-center justify-content-center border border-0 p-2 hover:bg-zinc-800 bg-transparent"
      [routerLink]="link.url"
    >
      <div [innerHTML]="link.svgHtml | safeHtml"></div>
      <a class="text-white text-decoration-none" [attr.aria-label]="link.label">
        {{ link.label }}
      </a>
    </button>
  </div>
</div>
