// src/app/services/websocket-events.ts

export const WebSocketEvents = {
  ALL_MATCHES: 'ALL_MATCHES',
  GET_MATCH: 'GET_MATCH',
  MATCH: 'MATCH',
  MATCH_UPDATED: 'MATCH_UPDATED',
  DELETE_MATCH: 'DELETE_MATCH',
  MATCH_DELETED: 'MATCH_DELETED',
  USER_UPDATED: 'USER_UPDATED',
  SUBSCRIBE_TO_MATCH_UPDATES: 'subscribeToMatchUpdates',
  SUBSCRIBE_TO_USER_UPDATES: 'subscribeToUserUpdates'
};

export interface WebSocketMessage {
  event: string;
  data?: any;
}
