import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  private endpoint = environment.endpoint + '/teams';

  constructor(private http: HttpClient) { }

  async getAllTeams() {
    return await firstValueFrom(this.http.get(`${this.endpoint}`));
  }

  async getTeam(_id: string) {
    return await firstValueFrom(this.http.get(`${this.endpoint}/team/${_id}`));
  }

  async getUserTeams(userId: string) {
    return await firstValueFrom(this.http.get<TeamCard[]>(`${this.endpoint}/user/${userId}`));
  }

  async updateTeamStats(_id: string, body: { stats: Stats }) {
    return await firstValueFrom(this.http.put(`${this.endpoint}/team/${_id}/stats`, body));
  }

  async sendTeamCard(teamCard: TeamCard) {
    return await firstValueFrom(this.http.put(`${this.endpoint}/team`, teamCard));
  }
}

interface Stats {
  earnings: number;
  played: number;
  wins: number;
  losses: number;
}

export interface TeamCard {
  _id: string;
  name: string;
  teamMembers: string[];
  stats: Stats;
}