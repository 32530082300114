<section class="container-fluid">
  <div class="container">
    <div class="my-4 card position-relative m-0 rounded p-4">
      <img
        class="rounded-2"
        [src]="url"
        alt="img"
        loading="lazy"
        width="100%"
        height="auto"
      />
      <div class="my-4">
        <div class="d-flex align-items-center justify-content-end gap-2 p-2">
          <img
            src="assets/img/Coin.svg"
            alt="BeatTheAura"
            width="32px"
            height="32px"
            class="mw-100 rounded-5"
            style="aspect-ratio: 1/1"
          />
          <h5 class="fw-bold">{{ price }}</h5>
        </div>
        <h3 class="d-flex justify-content-center">{{ name }}</h3>
      </div>
      <button
        *ngIf="userService.user"
        (click)="checkout.emit()"
        [disabled]="itemInInventory()"
        class="checkout d-inline-flex justify-content-center align-items-center px-0 btn btn-bd-premium text-uppercase"
      >
        {{ "SHOP.BUY" | translate }}
      </button>
      <button
        *ngIf="!userService.user"
        routerLink="/login"
        class="checkout d-inline-flex justify-content-center align-items-center h-2 px-3 mt-2 btn btn-bd-premium"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 448 512"
        >
          <path
            d="M224 256a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3 0 498.7 13.3 512 29.7 512h388.6c16.4 0 29.7-13.3 29.7-29.7 0-98.5-79.8-178.3-178.3-178.3h-91.4z"
            style="fill: var(--font)"
          />
        </svg>
        {{ "HOME.SESSION" | translate }}
      </button>
    </div>
  </div>
</section>
