<dashboard-sidebar>
  <section class="container">
    <div
      class="d-flex flex-column justify-content-center align-items-center flex-wrap gap-4"
    >
      <div class="w-100">
        <div
          class="d-flex flex-column justify-content-center align-items-center flex-wrap"
        >
          <div>
            <h1>{{ "MATCH.TITLE" | translate }}</h1>
          </div>
          <div>
            <p>
              {{ "MATCH.TIMER" | translate }}: {{ timeRemaining | timeFormat }}
            </p>
          </div>
          <div class="row">
            <div class="d-flex justify-content-center align-items-center gap-4">
              <div class="alert alert-warning" role="alert">
                {{ "MATCH.ENTRY" | translate }}: {{ match?.coins }}
              </div>
              <div class="pt-4 pb-4">
                <svg
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    d="M311 233c12 13 12 33 0 46L119 471a32 32 0 0 1-46-46l170-169L73 87a32 32 0 0 1 46-46l192 192z"
                    fill="var(--font)"
                  />
                </svg>
              </div>
              <div class="alert alert-success" role="alert">
                {{ "MATCH.PRIZE" | translate }}: +{{
                  amount(match?.coins) | number : "1.2-2"
                }}
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center gap-2">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Game</h5>
                  <h6 class="card-subtitle mb-2">Fortnite</h6>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                    {{ "MATCH.SIZE.TITLE" | translate }}
                  </h5>
                  <h6 class="card-subtitle mb-2">{{ match?.teamSize }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <div
              class="d-flex justify-content-start align-items-start scroll"
              style="overflow: auto"
            >
              <div style="flex-grow: 1; min-width: 250px">
                <div
                  class="gap-4 d-flex flex-column align-items-start justify-content-center"
                >
                  <div
                    class="d-flex justify-content-start align-items-center w-100"
                  >
                    <div
                      *ngIf="
                        status === 'open' ||
                        status === 'ongoing' ||
                        status === 'teamHostWon' ||
                        status === 'teamJoinWon' ||
                        status === 'bothTeamsWon' ||
                        status === 'finished'
                      "
                      style="
                        margin: 0px;
                        height: 26px;
                        width: 26px;
                        border-radius: 400px;
                        border: 2px solid rgb(50, 84, 104);
                        background-color: var(--button-primary);
                      "
                      class="d-flex flex-row align-items-center justify-content-center"
                    >
                      <svg
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M256 512a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm113-303L241 337c-9 9-25 9-34 0l-64-64c-9-9-9-25 0-34s25-9 34 0l47 47 111-111c9-9 25-9 34 0s9 25 0 34z"
                          fill="var(--font)"
                        />
                      </svg>
                    </div>
                    <div
                      *ngIf="
                        status === 'open' ||
                        status === 'ongoing' ||
                        status === 'teamHostWon' ||
                        status === 'teamJoinWon' ||
                        status === 'bothTeamsWon' ||
                        status === 'finished'
                      "
                      class="px-2"
                      style="flex-grow: 1"
                    >
                      <div
                        style="
                          height: 2px;
                          background-color: var(--button-primary);
                          width: 100%;
                        "
                      ></div>
                    </div>
                  </div>
                  <div style="max-width: 90%">
                    <h5>{{ "MATCH.WAITING.TITLE" | translate }}</h5>
                    <p>{{ "MATCH.WAITING.PARAGRAPH" | translate }}</p>
                  </div>
                </div>
              </div>
              <div style="flex-grow: 1; min-width: 250px">
                <div
                  class="gap-4 d-flex flex-column align-items-start justify-content-center"
                >
                  <div
                    *ngIf="
                      status === 'ongoing' ||
                      status === 'teamHostWon' ||
                      status === 'teamJoinWon' ||
                      status === 'bothTeamsWon' ||
                      status === 'finished'
                    "
                    class="d-flex justify-content-start align-items-center w-100"
                  >
                    <div
                      style="
                        margin: 0px;
                        height: 26px;
                        width: 26px;
                        border-radius: 400px;
                        border: 2px solid rgb(50, 84, 104);
                        background-color: var(--button-primary);
                      "
                      class="d-flex flex-row align-items-center justify-content-center"
                    >
                      <svg
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M256 512a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm113-303L241 337c-9 9-25 9-34 0l-64-64c-9-9-9-25 0-34s25-9 34 0l47 47 111-111c9-9 25-9 34 0s9 25 0 34z"
                          fill="var(--font)"
                        />
                      </svg>
                    </div>
                    <div class="px-2" style="flex-grow: 1">
                      <div
                        style="
                          height: 2px;
                          background-color: var(--button-primary);
                          width: 100%;
                        "
                      ></div>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      status !== 'ongoing' &&
                      status !== 'teamHostWon' &&
                      status !== 'teamJoinWon' &&
                      status !== 'bothTeamsWon' &&
                      status !== 'finished'
                    "
                    class="d-flex justify-content-start align-items-center w-100"
                  >
                    <div
                      style="
                        margin: 0px;
                        height: 26px;
                        width: 26px;
                        border-radius: 400px;
                        border: 2px solid rgb(50, 84, 104);
                        background-color: var(--muted);
                      "
                      class="d-flex flex-row align-items-center justify-content-center"
                    ></div>
                    <div class="px-2" style="flex-grow: 1">
                      <div
                        style="
                          height: 2px;
                          background-color: var(--muted);
                          width: 100%;
                        "
                      ></div>
                    </div>
                  </div>
                  <div style="max-width: 90%">
                    <h5>{{ "MATCH.PLAYING.TITLE" | translate }}</h5>
                    <p>{{ "MATCH.PLAYING.PARAGRAPH.1" | translate }}</p>
                    <p>{{ "MATCH.PLAYING.PARAGRAPH.2" | translate }}</p>
                  </div>
                </div>
              </div>
              <div style="flex-grow: 1; min-width: 250px">
                <div
                  class="gap-4 d-flex flex-column align-items-start justify-content-center"
                >
                  <div
                    *ngIf="
                      status === 'teamHostWon' ||
                      status === 'teamJoinWon' ||
                      status === 'bothTeamsWon' ||
                      status === 'finished'
                    "
                    class="d-flex justify-content-start align-items-center w-100"
                  >
                    <div
                      style="
                        margin: 0px;
                        height: 26px;
                        width: 26px;
                        border-radius: 400px;
                        border: 2px solid rgb(50, 84, 104);
                        background-color: var(--button-primary);
                      "
                      class="d-flex flex-row align-items-center justify-content-center"
                    >
                      <svg
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M256 512a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm113-303L241 337c-9 9-25 9-34 0l-64-64c-9-9-9-25 0-34s25-9 34 0l47 47 111-111c9-9 25-9 34 0s9 25 0 34z"
                          fill="var(--font)"
                        />
                      </svg>
                    </div>
                    <div class="px-2" style="flex-grow: 1">
                      <div
                        style="
                          height: 2px;
                          background-color: var(--button-primary);
                          width: 100%;
                        "
                      ></div>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      status !== 'teamHostWon' &&
                      status !== 'teamJoinWon' &&
                      status !== 'bothTeamsWon' &&
                      status !== 'finished'
                    "
                    class="d-flex justify-content-start align-items-center w-100"
                  >
                    <div
                      style="
                        margin: 0px;
                        height: 26px;
                        width: 26px;
                        border-radius: 400px;
                        border: 2px solid rgb(50, 84, 104);
                        background-color: var(--muted);
                      "
                      class="d-flex flex-row align-items-center justify-content-center"
                    ></div>
                    <div class="px-2" style="flex-grow: 1">
                      <div
                        style="
                          height: 2px;
                          background-color: var(--muted);
                          width: 100%;
                        "
                      ></div>
                    </div>
                  </div>
                  <div style="max-width: 90%">
                    <h5>{{ "MATCH.SUBMITTING.TITLE" | translate }}</h5>
                    <p>{{ "MATCH.SUBMITTING.PARAGRAPH.1" | translate }}</p>
                  </div>
                </div>
              </div>
              <div style="flex-grow: 1; min-width: 250px">
                <div
                  class="gap-4 d-flex flex-column align-items-start justify-content-center"
                >
                  <div
                    *ngIf="status === 'bothTeamsWon' || status === 'finished'"
                    class="d-flex justify-content-start align-items-center w-100"
                  >
                    <div
                      style="
                        margin: 0px;
                        height: 26px;
                        width: 26px;
                        border-radius: 400px;
                        border: 2px solid rgb(50, 84, 104);
                        background-color: var(--button-primary);
                      "
                      class="d-flex flex-row align-items-center justify-content-center"
                    >
                      <svg
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M256 512a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm113-303L241 337c-9 9-25 9-34 0l-64-64c-9-9-9-25 0-34s25-9 34 0l47 47 111-111c9-9 25-9 34 0s9 25 0 34z"
                          fill="var(--font)"
                        />
                      </svg>
                    </div>
                    <div class="px-2" style="flex-grow: 1">
                      <div
                        style="
                          height: 2px;
                          background-color: var(--button-primary);
                          width: 100%;
                        "
                      ></div>
                    </div>
                  </div>
                  <div
                    *ngIf="status !== 'bothTeamsWon' && status !== 'finished'"
                    class="d-flex justify-content-start align-items-center w-100"
                  >
                    <div
                      style="
                        margin: 0px;
                        height: 26px;
                        width: 26px;
                        border-radius: 400px;
                        border: 2px solid rgb(50, 84, 104);
                        background-color: var(--muted);
                      "
                      class="d-flex flex-row align-items-center justify-content-center"
                    ></div>
                    <div class="px-2" style="flex-grow: 1">
                      <div
                        style="
                          height: 2px;
                          background-color: var(--muted);
                          width: 100%;
                        "
                      ></div>
                    </div>
                  </div>
                  <div style="max-width: 90%">
                    <h5>{{ "MATCH.DISPUTED.TITLE" | translate }}</h5>
                    <div>{{ "MATCH.SUBMITTING.PARAGRAPH.1" | translate }}</div>
                  </div>
                </div>
              </div>
              <div style="flex-grow: 1; min-width: 250px">
                <div
                  class="gap-4 d-flex flex-column align-items-start justify-content-center"
                >
                  <div
                    *ngIf="status === 'finished'"
                    class="d-flex justify-content-start align-items-center w-100"
                  >
                    <div
                      style="
                        margin: 0px;
                        height: 26px;
                        width: 26px;
                        border-radius: 400px;
                        border: 2px solid rgb(50, 84, 104);
                        background-color: var(--button-primary);
                      "
                      class="d-flex flex-row align-items-center justify-content-center"
                    >
                      <svg
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M256 512a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm113-303L241 337c-9 9-25 9-34 0l-64-64c-9-9-9-25 0-34s25-9 34 0l47 47 111-111c9-9 25-9 34 0s9 25 0 34z"
                          fill="var(--font)"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    *ngIf="status !== 'finished'"
                    class="d-flex justify-content-start align-items-center w-100"
                  >
                    <div
                      style="
                        margin: 0px;
                        height: 26px;
                        width: 26px;
                        border-radius: 400px;
                        border: 2px solid rgb(50, 84, 104);
                        background-color: var(--muted);
                      "
                      class="d-flex flex-row align-items-center justify-content-center"
                    ></div>
                  </div>
                  <div style="max-width: 90%">
                    <h5>{{ "MATCH.COMPLETED.TITLE" | translate }}</h5>
                    <p>{{ "MATCH.COMPLETED.PARAGRAPH.1" | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col">
          <div class="card" style="max-width: 540px">
            <div class="card-header">
              <div
                class="d-flex flex-row justify-content-start align-items-start flex-wrap"
              >
                <p class="card-text d-flex flex-column">
                  <span>{{ "MATCH.TITLE" | translate }}</span>
                  <span>{{ "MATCH.MEMBERS" | translate }}</span>
                </p>
                <div *ngIf="checkIconTeam('teamHost') === 'winner'">
                  <svg
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M439 105c12 13 12 33 0 46L183 407a32 32 0 0 1-46 0L9 279a32 32 0 0 1 46-46l105 106 233-234c13-12 33-12 46 0z"
                      fill="var(--font)"
                    />
                  </svg>
                </div>
                <div *ngIf="checkIconTeam('teamHost') === 'loser'">
                  <svg
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M439 105c12 13 12 33 0 46L183 407a32 32 0 0 1-46 0L9 279a32 32 0 0 1 46-46l105 106 233-234c13-12 33-12 46 0z"
                      fill="var(--font)"
                    />
                  </svg>
                </div>
                <div *ngIf="checkIconTeam('teamHost') === 'none'"></div>
              </div>
            </div>
            <div *ngFor="let user of teamHostUsers" class="card-body d-flex">
              <div *ngIf="!user.savedUser.decorator">
                <img
                  [src]="user.savedUser.avatar"
                  loading="lazy"
                  class="img-thumbnail rounded-circle"
                  width="96px"
                  alt="..."
                />
              </div>
              <div
                *ngIf="user.savedUser.decorator"
                class="d-flex align-items-center justify-content-center"
              >
                <img
                  class="position-absolute"
                  [src]="user.savedUser.decorator"
                  width="128px"
                  alt=" "
                  aria-hidden="true"
                />
                <img
                  [src]="user.savedUser.avatar"
                  loading="lazy"
                  class="img-fluid rounded-circle"
                  width="96px"
                  alt="..."
                />
              </div>
              <div class="col-md-6">
                <div class="p-2">
                  <h5 class="card-title">{{ user.savedUser.username }}</h5>
                  <p class="card-text">{{ user.savedUser.epicId }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card" style="max-width: 540px" aria-hidden="true">
            <div *ngIf="teamJoinUsers.length <= 0">
              <div class="card-header">
                <div class="d-flex flex-column justify-content-start flex-wrap">
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                  </p>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-4"></span>
                  </p>
                </div>
              </div>
              <div class="card-body d-flex">
                <div class="col-3">
                  <img
                    src="https://i.imgur.com/rdvO6lD.png"
                    loading="lazy"
                    class="img-thumbnail rounded-circle"
                    alt=".."
                  />
                </div>
                <div class="col-md-6">
                  <div class="p-2">
                    <h5 class="card-title placeholder-glow">
                      <span class="placeholder col-6"></span>
                    </h5>
                    <p class="card-text placeholder-glow">
                      <span class="placeholder col-4"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="teamJoinUsers.length > 0">
              <div class="card-header">
                <div
                  class="d-flex flex-row justify-content-start align-items-start flex-wrap"
                >
                  <p class="card-text d-flex flex-column">
                    <span>{{ "MATCH.JOIN" | translate }}</span>
                    <span>{{ "MATCH.MEMBERS" | translate }}</span>
                  </p>
                  <div *ngIf="checkIconTeam('teamJoin') === 'winner'">
                    <svg
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M439 105c12 13 12 33 0 46L183 407a32 32 0 0 1-46 0L9 279a32 32 0 0 1 46-46l105 106 233-234c13-12 33-12 46 0z"
                        fill="var(--font)"
                      />
                    </svg>
                  </div>
                  <div *ngIf="checkIconTeam('teamJoin') === 'loser'">
                    <svg
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M439 105c12 13 12 33 0 46L183 407a32 32 0 0 1-46 0L9 279a32 32 0 0 1 46-46l105 106 233-234c13-12 33-12 46 0z"
                        fill="var(--font)"
                      />
                    </svg>
                  </div>
                  <div *ngIf="checkIconTeam('teamJoin') === 'none'"></div>
                </div>
              </div>
              <div *ngFor="let user of teamJoinUsers" class="card-body d-flex">
                <div *ngIf="!user.savedUser.decorator">
                  <img
                    [src]="user.savedUser.avatar"
                    loading="lazy"
                    class="img-fluid rounded-circle"
                    width="96px"
                    alt="..."
                  />
                </div>
                <div
                  *ngIf="user.savedUser.decorator"
                  class="d-flex align-items-center justify-content-center"
                >
                  <img
                    class="position-absolute"
                    [src]="user.savedUser.decorator"
                    width="128px"
                    alt=" "
                    aria-hidden="true"
                  />
                  <img
                    [src]="user.savedUser.avatar"
                    loading="lazy"
                    class="img-fluid rounded-circle"
                    width="96px"
                    alt="..."
                  />
                </div>
                <div class="col-md-6">
                  <div class="p-2">
                    <h5 class="card-title">{{ user.savedUser.username }}</h5>
                    <p class="card-text">{{ user.savedUser.epicId }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div *ngIf="status === 'open'">
            <div class="d-flex flex-column flex-wrap row-gap-2">
              <div class="card" style="max-width: 540px" aria-hidden="true">
                <div class="card-header">
                  <div class="d-flex flex-column flex-wrap row-gap-2">
                    <h2>
                      <svg
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M176 0a32 32 0 1 0 0 64h16v34a208 208 0 1 0 207 93l24-24a32 32 0 0 0-46-46l-21 22c-28-23-63-39-100-45V64h16a32 32 0 1 0 0-64h-96zm72 192v128a24 24 0 1 1-48 0V192a24 24 0 1 1 48 0z"
                          fill="var(--font)"
                        /></svg
                      >{{ timeRemaining | timeFormat }}
                    </h2>
                  </div>
                </div>
              </div>
              <div class="card" style="max-width: 540px" aria-hidden="true">
                <div class="card-header">
                  <a
                    href="https://discord.com/channels/597084607447564319/705444416151879842"
                    target="_blank"
                    class="btn btn-bd-primary"
                  >
                    <svg
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path
                        d="M525 70a2 2 0 0 0-1-1 485 485 0 0 0-120-37 2 2 0 0 0-2 1 338 338 0 0 0-15 31 448 448 0 0 0-134 0 310 310 0 0 0-15-31 2 2 0 0 0-2-1 484 484 0 0 0-120 37 2 2 0 0 0-1 1 497 497 0 0 0-87 334 2 2 0 0 0 1 2 488 488 0 0 0 147 74 2 2 0 0 0 2-1 348 348 0 0 0 30-49 2 2 0 0 0-1-2 321 321 0 0 1-46-22 2 2 0 0 1 0-3l9-7a2 2 0 0 1 2-1c96 44 200 44 296 0a2 2 0 0 1 1 1l9 7a2 2 0 0 1 0 3 301 301 0 0 1-46 22 2 2 0 0 0-1 2 391 391 0 0 0 30 49 2 2 0 0 0 3 1 486 486 0 0 0 147-74 2 2 0 0 0 1-2c12-126-21-236-87-334zM223 338c-29 0-53-27-53-60s23-59 53-59c29 0 53 27 52 59 0 33-23 60-52 60zm195 0c-29 0-53-27-53-60s23-59 53-59 53 27 53 59c0 33-23 60-53 60z"
                        fill="var(--font)"
                      />
                    </svg>
                    {{ "MATCH.TICKET" | translate }}
                  </a>
                </div>
                <div class="row g-0">
                  <div class="p-3">
                    <span>{{ "MATCH.DISCORD" | translate }}</span>
                    <hr />
                    <span>{{ "MATCH.PARAGRAPH.1" | translate }}</span>
                    <hr />
                    <span>{{ "MATCH.PARAGRAPH.2" | translate }}</span>
                    <hr />
                    <span>{{ "MATCH.PARAGRAPH.3" | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              status === 'ongoing' ||
              status === 'teamHostWon' ||
              status === 'teamJoinWon' ||
              status === 'bothTeamsWon'
            "
          >
            <div *ngIf="userBelongsToTeam() === true">
              <button
                type="button"
                class="btn btn-primary w-100"
                (click)="openDialog(match._id)"
              >
                Submit Results
              </button>
            </div>
            <div *ngIf="userBelongsToTeam() === false"></div>
            <div class="card" style="max-width: 540px" aria-hidden="true">
              <div class="card-header">
                <a
                  href="https://discord.com/channels/597084607447564319/705444416151879842"
                  target="_blank"
                  class="btn btn-bd-primary"
                >
                  <svg
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      d="M525 70a2 2 0 0 0-1-1 485 485 0 0 0-120-37 2 2 0 0 0-2 1 338 338 0 0 0-15 31 448 448 0 0 0-134 0 310 310 0 0 0-15-31 2 2 0 0 0-2-1 484 484 0 0 0-120 37 2 2 0 0 0-1 1 497 497 0 0 0-87 334 2 2 0 0 0 1 2 488 488 0 0 0 147 74 2 2 0 0 0 2-1 348 348 0 0 0 30-49 2 2 0 0 0-1-2 321 321 0 0 1-46-22 2 2 0 0 1 0-3l9-7a2 2 0 0 1 2-1c96 44 200 44 296 0a2 2 0 0 1 1 1l9 7a2 2 0 0 1 0 3 301 301 0 0 1-46 22 2 2 0 0 0-1 2 391 391 0 0 0 30 49 2 2 0 0 0 3 1 486 486 0 0 0 147-74 2 2 0 0 0 1-2c12-126-21-236-87-334zM223 338c-29 0-53-27-53-60s23-59 53-59c29 0 53 27 52 59 0 33-23 60-52 60zm195 0c-29 0-53-27-53-60s23-59 53-59 53 27 53 59c0 33-23 60-53 60z"
                      fill="var(--font)"
                    />
                  </svg>
                  {{ "MATCH.TICKET" | translate }}
                </a>
              </div>
              <div class="row g-0">
                <div class="p-3">
                  <span>{{ "MATCH.DISCORD" | translate }}</span>
                  <hr />
                  <span>{{ "MATCH.PARAGRAPH.1" | translate }}</span>
                  <hr />
                  <span>{{ "MATCH.PARAGRAPH.2" | translate }}</span>
                  <hr />
                  <span>{{ "MATCH.PARAGRAPH.3" | translate }}</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="status === 'finished'">
            <div class="card" style="max-width: 540px" aria-hidden="true">
              <div class="card-header">
                <a
                  href="https://discord.com/channels/597084607447564319/705444416151879842"
                  target="_blank"
                  class="btn btn-bd-primary"
                >
                  <svg
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      d="M525 70a2 2 0 0 0-1-1 485 485 0 0 0-120-37 2 2 0 0 0-2 1 338 338 0 0 0-15 31 448 448 0 0 0-134 0 310 310 0 0 0-15-31 2 2 0 0 0-2-1 484 484 0 0 0-120 37 2 2 0 0 0-1 1 497 497 0 0 0-87 334 2 2 0 0 0 1 2 488 488 0 0 0 147 74 2 2 0 0 0 2-1 348 348 0 0 0 30-49 2 2 0 0 0-1-2 321 321 0 0 1-46-22 2 2 0 0 1 0-3l9-7a2 2 0 0 1 2-1c96 44 200 44 296 0a2 2 0 0 1 1 1l9 7a2 2 0 0 1 0 3 301 301 0 0 1-46 22 2 2 0 0 0-1 2 391 391 0 0 0 30 49 2 2 0 0 0 3 1 486 486 0 0 0 147-74 2 2 0 0 0 1-2c12-126-21-236-87-334zM223 338c-29 0-53-27-53-60s23-59 53-59c29 0 53 27 52 59 0 33-23 60-52 60zm195 0c-29 0-53-27-53-60s23-59 53-59 53 27 53 59c0 33-23 60-53 60z"
                      fill="var(--font)"
                    />
                  </svg>
                  {{ "MATCH.TICKET" | translate }}
                </a>
              </div>
              <div class="row g-0">
                <div class="p-3">
                  <span>{{ "MATCH.DISCORD" | translate }}</span>
                  <hr />
                  <span>{{ "MATCH.PARAGRAPH.1" | translate }}</span>
                  <hr />
                  <span>{{ "MATCH.PARAGRAPH.2" | translate }}</span>
                  <hr />
                  <span>{{ "MATCH.PARAGRAPH.3" | translate }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</dashboard-sidebar>
