<navbar id="homeNavbar"></navbar>
<header class="container">
  <form>
    <div class="d-flex flex-column gap-2">
      <div class="d-flex align-items-center justify-content-between">
        <div *ngIf="!decorator.value">
          <img
            [src]="avatar.value"
            loading="lazy"
            class="img-fluid rounded-circle"
            width="96px"
            alt="..."
          />
        </div>
        <div
          *ngIf="decorator.value"
          class="d-flex align-items-center justify-content-center"
        >
          <img
            class="position-absolute"
            [src]="decorator.value"
            width="148px"
            height="148px"
            alt=" "
            aria-hidden="true"
          />
          <img
            [src]="avatar.value"
            loading="lazy"
            class="img-fluid rounded-circle"
            width="124px"
            height="124px"
            alt="..."
          />
        </div>
        <div>
          <label>{{ "USER.DECORATOR.TITLE" | translate }}</label>
          <select
            class="p-2 w-100 border-0 rounded text-font bg-tertiary"
            [formControl]="decorator"
          >
            <option *ngFor="let item of savedUser.inventory" [value]="item.img">
              {{ item.name }}
            </option>
          </select>
          <div *ngIf="decorator.invalid && decorator.errors['required']">
            {{ "USER.DECORATOR.ERROR" | translate }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-column gap-2">
        <div class="d-flex flex-column gap-2">
          <label>{{ "USER.AVATAR.TITLE" | translate }}</label>
          <input
            class="p-2 w-100 border border-0 rounded text-font bg-tertiary"
            type="text"
            placeholder="BeatTheAura"
            [formControl]="avatar"
          />
          <div *ngIf="avatar.invalid && avatar.errors['required']">
            {{ "USER.AVATAR.TITLE" | translate }}
          </div>
        </div>
        <div class="d-flex flex-column gap-2">
          <label>{{ "USER.USERNAME.TITLE" | translate }}</label>
          <input
            class="p-2 w-100 border border-0 rounded text-font bg-tertiary"
            type="text"
            placeholder="BeatTheAura"
            [formControl]="username"
          />
          <div *ngIf="username.invalid && username.errors['required']">
            {{ "USER.USERNAME.ERROR" | translate }}
          </div>
        </div>
        <div class="d-flex flex-column gap-2">
          <label>{{ "USER.EPICGAMES.TITLE" | translate }}</label>
          <input
            class="p-2 w-100 border border-0 rounded text-font bg-tertiary"
            type="text"
            placeholder="BeatTheAura"
            [formControl]="epicId"
          />
          <div *ngIf="epicId.invalid && epicId.errors['required']">
            {{ "USER.EPICGAMES.TITLE" | translate }}
          </div>
        </div>
      </div>
      <button
        class="btn btn-bd-primary"
        (click)="submitUser()"
        [disabled]="
          (avatar.invalid && avatar.errors['required']) ||
          (username.invalid && username.errors['required']) ||
          (epicId.invalid && epicId.errors['required'])
        "
      >
        {{ "MODAL.SAVE" | translate }}
      </button>
    </div>
  </form>
</header>
<hr />
<section class="container">
  <div class="d-flex justify-content-center align-items-center flex-wrap">
    <div class="w-100">
      <div class="d-flex justify-content-center align-items-center flex-wrap">
        <div class="w-100">
          <div
            class="d-flex justify-content-center align-items-center flex-wrap"
          >
            <div>
              <div
                class="btn-group p-1"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <input
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="btnradio1"
                  (click)="buttonClicked = 'teams'"
                  value="teams"
                  checked
                />
                <label class="btn-toggle p-2" for="btnradio1">Teams</label>

                <input
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="btnradio2"
                  (click)="buttonClicked = 'links'"
                  value="links"
                />
                <label class="btn-toggle p-2" for="btnradio2"
                  >Links Accounts</label
                >

                <input
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="btnradio3"
                  (click)="buttonClicked = 'tips'"
                  value="tips"
                />
                <label class="btn-toggle p-2" for="btnradio3">Tips</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-wrap">
        <div *ngIf="buttonClicked === 'teams'" class="w-100">
          <div
            class="d-flex flex-row align-items-center justify-content-between"
          >
            <h3>YOUR TEAMS</h3>
            <button
              class="btn btn-bd-primary"
              data-bs-toggle="modal"
              data-bs-target="#create-team-card"
            >
              <svg
                width="24"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M256 512a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm-24-168v-64h-64a24 24 0 1 1 0-48h64v-64a24 24 0 1 1 48 0v64h64a24 24 0 1 1 0 48h-64v64a24 24 0 1 1-48 0z"
                  fill="var(--font)"
                />
              </svg>
              Create Team
            </button>
          </div>
          <div class="w-100">
            <div
              class="d-flex justify-content-center align-items-center flex-wrap"
            >
              <div class="w-100 gap-2">
                <div
                  *ngIf="!(teams && teams.length > 0)"
                  class="d-flex justify-content-between align-items-center flex-column"
                >
                  <h1>Not teams</h1>
                  <span>Try creating a team</span>
                </div>
                <div
                  *ngIf="teams.length > 0"
                  class="d-flex flex-column align-items-center justify-content-between gap-2"
                >
                  <div *ngFor="let team of teams" class="w-100">
                    <div
                      class="cardFVB"
                      (click)="
                        openDialog(
                          team._id,
                          team.name,
                          team.teamMembers,
                          team.stats.earnings,
                          team.stats.played,
                          team.stats.wins,
                          team.stats.losses
                        )
                      "
                    >
                      <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center gap-2">
                          <img
                            class="rounded"
                            src="../../../assets/img/Fortnite_icon.webp"
                            loading="lazy"
                            alt=""
                            width="64"
                            height="64"
                          />
                          <div class="d-flex flex-column align-items-start">
                            <svg
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 640 512"
                            >
                              <path
                                d="M72 88a56 56 0 1 1 112 0 56 56 0 1 1-112 0zm-8 158a63 63 0 0 0 0 84v-84zm144-50a143 143 0 0 0-16 199v21c0 18-14 32-32 32H96c-18 0-32-14-32-32v-27a112 112 0 0 1 48-213h32c24 0 46 8 64 20zm240 220v-21a143 143 0 0 0-16-199c18-12 40-20 64-20h32a112 112 0 0 1 48 213v27c0 18-14 32-32 32h-64c-18 0-32-14-32-32zm8-328a56 56 0 1 1 112 0 56 56 0 1 1-112 0zm120 158v84a64 64 0 0 0 0-84zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm-80 272c0 16 6 31 16 42v-84a64 64 0 0 0-16 42zm144-42v84a64 64 0 0 0 0-84zm64 42c0 45-26 83-64 101v43c0 18-14 32-32 32h-64c-18 0-32-14-32-32v-43a112 112 0 0 1 48-213h32c62 0 112 50 112 112z"
                                fill="var(--font)"
                              /></svg
                            ><span>{{ team.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="buttonClicked === 'links'" class="w-100">
          <div class="d-flex flex-row align-items-center gap-3">
            <svg
              width="24"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M364 84a63 63 0 0 0-88 0L92 268a108 108 0 1 0 152 152l152-152a28 28 0 0 1 40 40L284 460A164 164 0 1 1 52 228L236 44a118 118 0 1 1 168 168L228 388a73 73 0 0 1-104-104l144-144a28 28 0 0 1 40 40L164 324c-7 7-7 18 0 24s17 7 24 0l176-176c25-24 25-64 0-88z"
                fill="var(--font)"
              />
            </svg>
            <h3>Linked Accounts</h3>
          </div>
          <div class="w-100">
            <div
              class="d-flex justify-content-center align-items-center flex-wrap"
            >
              <div class="w-100"></div>
            </div>
          </div>
        </div>
        <div *ngIf="buttonClicked === 'tips'" class="w-100">
          <div class="w-100">
            <h3>Tips</h3>
          </div>
          <div class="w-100">
            <div
              class="d-flex justify-content-center align-items-center flex-wrap"
            >
              <div class="w-100">
                <div
                  class="d-flex justify-content-between align-items-center flex-column"
                >
                  <h1>TIPS (COMMING SOON)</h1>
                  <span>Try tipping a friend</span>
                </div>
                <div *ngIf="true">
                  <div class="css-uewffw"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="create-team-card"
  tabindex="-1"
  role="dialog"
  aria-labelledby="create-team-card"
  aria-hidden="true"
>
  <create-team-card></create-team-card>
</div>
