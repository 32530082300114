<div class="p-2">
  <h1>{{ "SUBMIT.TITLE" | translate}}</h1>
  <hr>
  <span>{{ "SUBMIT.SPAN" | translate}}</span>
  <section class="example-section">
    <p class="d-flex align-items-center gap-2"><input type="checkbox" [(ngModel)]="enabled">{{ "SUBMIT.CHECKBOX" | translate}}
    </p>
  </section>
  <hr>
  <button type="button" class="btn btn-secondary" [disabled]="!enabled" (click)="TeamLost()">{{ "SUBMIT.BUTTONS.LOSSER" | translate}}</button>
  <button type="button" class="btn btn-primary" [disabled]="!enabled || status === 'bothTeamsWon'" (click)="TeamWon()">{{ "SUBMIT.BUTTONS.WINNER" | translate}}</button>
</div>