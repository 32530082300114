<div class="modal-content">
  <form #f="ngForm">
    <div class="d-flex align-items-center justify-content-between p-4">
      <div class="d-flex align-items-center w-100">
        <h5 class="m-0">{{ this.data.teamName }}</h5>
        <button class="btn" (click)="editName()">
          <svg
            width="24"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M472 22a56 56 0 0 0-80 0l-30 30 98 98 30-30c22-22 22-58 0-80l-18-18zM172 242c-6 6-10 13-13 22l-30 88a24 24 0 0 0 31 31l89-30c8-3 15-7 21-13l168-168-98-98-168 168zM96 64c-53 0-96 43-96 96v256c0 53 43 96 96 96h256c53 0 96-43 96-96v-96a32 32 0 1 0-64 0v96c0 18-14 32-32 32H96c-18 0-32-14-32-32V160c0-18 14-32 32-32h96a32 32 0 1 0 0-64H96z"
              fill="var(--font)"
            />
          </svg>
        </button>
      </div>
      <mat-dialog-actions>
        <button class="btn-close" mat-dialog-close></button>
      </mat-dialog-actions>
    </div>
    <div
      class="d-flex flex-column align-items-center justify-content-between p-4"
    >
      <div
        class="btn-group p-1 d-flex align-items-center justify-content-center"
        role="groupModalTeamCard"
        aria-label="Basic radio toggle button group"
      >
        <input
          type="radio"
          class="btn-check"
          name="btnmodalteamcard"
          id="btnmodalteamcard1"
          (click)="ModalTeamCard = 'stats'"
          value="stats"
          checked
        />
        <label class="btn-toggle p-2 cursor-pointer" for="btnmodalteamcard1">{{
          "TEAM.CARD.STATS.TITLE" | translate
        }}</label>

        <input
          type="radio"
          class="btn-check"
          name="btnmodalteamcard"
          id="btnmodalteamcard2"
          (click)="ModalTeamCard = 'roster'"
          value="roster"
        />
        <label class="btn-toggle p-2 cursor-pointer" for="btnmodalteamcard2">{{
          "TEAM.CARD.ROSTER.TITLE" | translate
        }}</label>
      </div>
      <div class="d-flex align-items-center flex-column">
        <div *ngIf="ModalTeamCard === 'stats'" class="w-100">
          <div>
            <h3>{{ "TEAM.CARD.STATS.H" | translate }}</h3>
            <div
              class="d-flex flex-wrap align-items-center justify-content-between gap-2"
            >
              <div class="cardFVB">
                <div
                  class="d-flex flex-column justify-content-between align-items-center gap-2"
                >
                  <img
                    src="assets/img/Coin.svg"
                    alt="BeatTheAura"
                    width="32px"
                    height="32px"
                    class="mw-100 rounded-5"
                    style="aspect-ratio: 1/1"
                  />
                  <div class="d-flex flex-column align-items-start">
                    <span>{{ "TEAM.CARD.STATS.EARNINGS" | translate }}</span>
                    <span>{{ this.data.teamStats.earnings }}</span>
                  </div>
                </div>
              </div>
              <div class="cardFVB">
                <div
                  class="d-flex flex-column justify-content-between align-items-center gap-2"
                >
                  <svg
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="m40 467-11 9c-3 3-7 4-11 4-10 0-18-8-18-18V192a192 192 0 0 1 384 0v270a18 18 0 0 1-29 14l-11-9c-14-11-33-9-44 4l-31 35a18 18 0 0 1-26 0l-27-30a32 32 0 0 0-48 0l-27 30a18 18 0 0 1-26 0l-31-35a32 32 0 0 0-44-4zm120-275a32 32 0 1 0-64 0 32 32 0 1 0 64 0zm96 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
                      style="fill: var(--font)"
                    />
                  </svg>
                  <div class="d-flex flex-column align-items-start">
                    <span>{{ "TEAM.CARD.STATS.PLAYED" | translate }}</span>
                    <span>{{ this.data.teamStats.played }}</span>
                  </div>
                </div>
              </div>
              <div class="cardFVB">
                <div
                  class="d-flex flex-column justify-content-between align-items-center gap-2"
                >
                  <svg
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="var(--font)"
                      d="M400 0H176c-26 0-48 22-47 48l1 16H24C11 64 0 75 0 88c0 93 34 157 79 201 44 43 98 64 138 75 23 7 39 27 39 46 0 21-17 38-38 38h-26a32 32 0 1 0 0 64h192a32 32 0 1 0 0-64h-26c-21 0-38-17-38-38 0-19 16-39 39-45 40-11 94-33 139-76 45-44 78-108 78-201 0-13-11-24-24-24H446l1-16c1-26-20-48-47-48zM49 112h84c9 90 29 150 52 191-25-11-51-27-73-49-32-31-58-76-63-142zm415 142c-22 22-48 38-73 49 23-41 43-101 52-191h84c-5 66-31 111-63 142z"
                    />
                  </svg>
                  <div class="d-flex flex-column align-items-start">
                    <span>{{ "TEAM.CARD.STATS.WINS" | translate }}</span>
                    <span>{{ this.data.teamStats.wins }}</span>
                  </div>
                </div>
              </div>
              <div class="cardFVB">
                <div
                  class="d-flex flex-column justify-content-between align-items-center gap-2"
                >
                  <svg
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M416 399c59-41 96-104 96-175C512 100 397 0 256 0S0 100 0 224c0 71 38 134 96 175v65c0 27 22 48 48 48h48v-48c0-9 7-16 16-16s16 7 16 16v48h64v-48c0-9 7-16 16-16s16 7 16 16v48h48c27 0 48-21 48-48v-65zM96 256a64 64 0 1 1 128 0 64 64 0 1 1-128 0zm256-64a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                      style="fill: var(--font)"
                    />
                  </svg>
                  <div class="d-flex flex-column align-items-start">
                    <span>{{ "TEAM.CARD.STATS.LOSSES" | translate }}</span>
                    <span>{{ this.data.teamStats.losses }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="ModalTeamCard === 'roster'" class="w-100">
          <div>
            <div class="d-flex align-items-center justify-content-start gap-2">
              <svg
                width="24"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path
                  d="M144 0a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm368 0a80 80 0 1 1 0 160 80 80 0 1 1 0-160zM0 299c0-59 48-107 107-107h42c16 0 31 4 45 10l-2 22c0 38 17 73 43 96H21c-11 0-21-10-21-21zm405 21a128 128 0 0 0 41-118c14-7 29-10 45-10h42c59 0 107 48 107 107 0 11-10 21-21 21H405zm-181-96a96 96 0 1 1 192 0 96 96 0 1 1-192 0zm-96 261c0-73 60-133 133-133h118c73 0 133 60 133 133 0 15-12 27-27 27H155c-15 0-27-12-27-27z"
                  style="fill: var(--font)"
                />
              </svg>
              <h3>{{ "TEAM.CARD.ROSTER.H" | translate }}</h3>
            </div>
            <div
              class="d-flex flex-wrap align-items-center justify-content-between gap-2"
            >
              <div
                *ngFor="let member of teamMemberDetails; let i = index"
                class="cardFVB"
              >
                <div
                  class="d-flex flex-column justify-content-between align-items-center gap-2"
                >
                  <img
                    [src]="member.avatar"
                    class="rounded"
                    width="64"
                    height="64"
                    alt="Avatar"
                  />
                  <div class="d-flex align-items-center gap-2">
                    <span>{{ member.name }}</span>
                    <button
                      class="btn"
                      *ngIf="i !== 0"
                      (click)="removeMember(i)"
                    >
                      <svg
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          d="M96 128a128 128 0 1 1 256 0 128 128 0 1 1-256 0zM0 482c0-98 80-178 178-178h92c98 0 178 80 178 178 0 17-13 30-30 30H30c-17 0-30-13-30-30zm472-282h144a24 24 0 1 1 0 48H472a24 24 0 1 1 0-48z"
                          style="fill: #ff0000"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between p-4">
      <div class="d-flex w-100">
        <button class="btn btn-primary" (click)="invite()">Invite</button>
      </div>
    </div>
  </form>
</div>
