<navbar id="homeNavbar"></navbar>
<section class="min-vh-100 container py-4">
  <h1 class="d-flex align-items-center fw-bold gap-2">
    <div class="jpcFTu d-flex justify-content-center align-items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56px"
        viewBox="1.001 22.356 510.194 445.382"
      >
        <path
          fill="var(--font)"
          d="M355.302 22.356H156.894c-23.03 0-42.517 19.138-41.631 41.755l.886 13.919h-93.89c-11.515 0-21.258 9.567-21.258 20.877 0 80.899 30.115 136.573 69.974 174.845 38.973 37.406 86.804 55.674 122.234 65.243 20.373 6.089 34.544 23.487 34.544 40.015 0 18.268-15.057 33.054-33.658 33.054h-23.03c-21.819 0-35.456 23.198-24.546 41.755 5.063 8.613 14.42 13.919 24.546 13.919h170.066c21.819 0 35.456-23.197 24.546-41.755-5.063-8.612-14.42-13.919-24.546-13.919h-23.03c-18.601 0-33.658-14.786-33.658-33.054 0-16.528 14.171-33.926 34.544-39.145 35.43-9.569 83.261-28.707 123.12-66.113 39.858-38.272 69.088-93.946 69.088-174.845 0-11.31-9.743-20.877-21.258-20.877h-93.89l.886-13.919c.886-22.617-17.715-41.755-41.631-41.755ZM44.403 119.783h74.403c7.972 78.291 25.687 130.483 46.06 166.149-22.144-9.569-45.174-23.487-64.661-42.625-28.344-26.965-51.373-66.11-55.802-123.524Zm367.588 123.524c-19.487 19.138-42.517 33.056-64.661 42.625 20.373-35.666 38.088-87.858 46.06-166.149h74.403c-4.429 57.414-27.458 96.559-55.802 123.524Z"
        ></path>
      </svg>
    </div>
    {{ "HOME.LEADERBOARD" | translate }}
  </h1>
  <div
    class="py-5"
    style="
      overflow: auto;
      white-space: nowrap;
      scrollbar-width: none;
    "
  >
    <div
      class="d-flex flex-column justify-content-start flex-wrap gap-2 w-100 rounded"
    >
      <div *ngFor="let member of members" class="d-inline-flex gap-2 w-100">
        <span
          [class]="'rank-' + (members.indexOf(member) + 1)"
          class="d-flex justify-content-center align-items-center rounded-2 shadow-sm p-4 fs-5 fw-bolder"
          >{{ members.indexOf(member) + 1 }}</span
        >
        <div
          class="d-flex justify-content-start align-items-center rounded-2 shadow-sm w-100 px-4 py-2 gap-4 bg-tertiary fs-5 fw-bolder"
        >
          <div *ngIf="!member.decorator">
            <img
              [src]="member.avatar"
              loading="lazy"
              class="img-fluid rounded-circle"
              width="64px"
              height="64px"
              alt="..."
            />
          </div>
          <div
            *ngIf="member.decorator"
            class="d-flex align-items-center justify-content-center"
          >
            <img
              loading="lazy"
              class="img-fluid position-absolute"
              [src]="member.decorator"
              width="72px"
              height="72px"
              alt="..."
              aria-hidden="true"
            />
            <img
              [src]="member.avatar"
              loading="lazy"
              class="img-fluid rounded-circle"
              width="64px"
              height="64px"
              alt="..."
            />
          </div>
          {{ member.username }}
        </div>
        <div
          class="d-flex justify-content-center align-items-center rounded-2 shadow-sm px-4 gap-2 bg-tertiary fs-5 fw-bolder"
        >
          <img
            src="assets/img/Coin.svg"
            alt="BeatTheAura"
            width="25px"
            height="25px"
            class="mw-100 rounded-5"
            style="aspect-ratio: 1/1"
          />
          {{ member.coins | number : "1.2-2" }}
        </div>
      </div>
    </div>
  </div>
</section>
<footer></footer>
