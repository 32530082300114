import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TeamCard } from './team.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MatchService {

  endpoint = environment.endpoint + '/matches';
  private _match: any;
  get match() { return this._match; }

  private get key() {
    return localStorage.getItem('key');
  }

  constructor(private http: HttpClient) { }

  async init() {
    if (!this.match) await console.log('No matches found, updating...');
  }

  async getAllMatches(): Promise<any[]> {
    return await firstValueFrom(this.http.get<any[]>(`${this.endpoint}`));
  }

  async sendMatchCard(matchCard: MatchCard) {
    return await firstValueFrom(this.http.put(`${this.endpoint}/match?key=${this.key}`, matchCard));
  }

  async getMatch(id: string): Promise<any> {
    return await firstValueFrom(this.http.get(`${this.endpoint}/match/${id}`));
  }

  async delMatch(id: string): Promise<any> {
    return await firstValueFrom(this.http.delete(`${this.endpoint}/match/${id}`));
  }

  async sendMatchJoin(id: string, body: { teamJoin: TeamCard, status: 'ongoing', endTime: Date }) {
    return await firstValueFrom(this.http.put(`${this.endpoint}/match/${id}/join`, body));
  }

  async sendMatchSubmit(id: string, body?: { submitresult?: { winner?: string[], loser?: string[] }, status: string, endTime?: Date }) {
    console.log(body);
    return await firstValueFrom(this.http.put(`${this.endpoint}/match/${id}/submit`, body));
  }
}

interface SubmitResult {
  winner: string[];
  loser: string[];
}

export interface MatchCard {
  _id: string;
  mode: string;
  bestOf: string;
  teamSize: string;
  teamHost: TeamCard;
  teamJoin?: TeamCard;
  coins: number;
  startTime: Date;
  endTime: Date;
  submitresult: SubmitResult;
  status: string;
}