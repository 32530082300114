<navbar id="homeNavbar"></navbar>
<header class="container-fluid pt-5">
  <div class="d-flex flex-column gap-2">
    <div class="d-flex justify-content-center placeholder-glow">
      <h1
        class="placeholder col-6"
        style="text-shadow: #000000 1px 0 10px"
      ></h1>
    </div>
    <div class="d-flex justify-content-center placeholder-glow">
      <h1
        class="placeholder col-3"
        style="text-shadow: #000000 1px 0 10px"
      ></h1>
    </div>
    <div class="row">
      <div class="d-flex justify-content-center gap-3 placeholder-glow">
        <a
          class="btn btn-secondary disabled placeholder col-1"
          aria-disabled="true"
        ></a>
        <a
          class="btn btn-bd-primary disabled placeholder col-1"
          aria-disabled="true"
        ></a>
      </div>
    </div>
  </div>
</header>
<section class="m-4 p-4 rounded-4 border border-2">
  <div class="d-flex flex-column gap-2 placeholder-glow">
    <h2 class="placeholder col-4" style="text-shadow: #000000 1px 0 10px"></h2>
    <div class="css-grid">
      <div class="card" aria-hidden="true">
        <svg
          class="bd-placeholder-img card-img-top"
          width="100%"
          height="180"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Placeholder"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#868e96"></rect>
        </svg>
        <div class="card-body">
          <h5 class="card-title placeholder-glow">
            <span class="placeholder col-6"></span>
          </h5>
          <p class="card-text placeholder-glow">
            <span class="placeholder col-10"></span>
            <span class="placeholder col-6"></span>
          </p>
          <a
            class="btn btn-secondary disabled placeholder w-100"
            aria-disabled="true"
          ></a>
        </div>
      </div>
      <div class="card" aria-hidden="true">
        <svg
          class="bd-placeholder-img card-img-top"
          width="100%"
          height="180"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Placeholder"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#868e96"></rect>
        </svg>
        <div class="card-body">
          <h5 class="card-title placeholder-glow">
            <span class="placeholder col-6"></span>
          </h5>
          <p class="card-text placeholder-glow">
            <span class="placeholder col-10"></span>
            <span class="placeholder col-6"></span>
          </p>
          <a
            class="btn btn-secondary disabled placeholder w-100"
            aria-disabled="true"
          ></a>
        </div>
      </div>
      <div class="card" aria-hidden="true">
        <svg
          class="bd-placeholder-img card-img-top"
          width="100%"
          height="180"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Placeholder"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#868e96"></rect>
        </svg>
        <div class="card-body">
          <h5 class="card-title placeholder-glow">
            <span class="placeholder col-6"></span>
          </h5>
          <p class="card-text placeholder-glow">
            <span class="placeholder col-10"></span>
            <span class="placeholder col-6"></span>
          </p>
          <a
            class="btn btn-secondary disabled placeholder w-100"
            aria-disabled="true"
          ></a>
        </div>
      </div>
      <div class="card" aria-hidden="true">
        <svg
          class="bd-placeholder-img card-img-top"
          width="100%"
          height="180"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Placeholder"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#868e96"></rect>
        </svg>
        <div class="card-body">
          <h5 class="card-title placeholder-glow">
            <span class="placeholder col-6"></span>
          </h5>
          <p class="card-text placeholder-glow">
            <span class="placeholder col-10"></span>
            <span class="placeholder col-6"></span>
          </p>
          <a
            class="btn btn-secondary disabled placeholder w-100"
            aria-disabled="true"
          ></a>
        </div>
      </div>
      <div class="card" aria-hidden="true">
        <svg
          class="bd-placeholder-img card-img-top"
          width="100%"
          height="180"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-label="Placeholder"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#868e96"></rect>
        </svg>
        <div class="card-body">
          <h5 class="card-title placeholder-glow">
            <span class="placeholder col-6"></span>
          </h5>
          <p class="card-text placeholder-glow">
            <span class="placeholder col-10"></span>
            <span class="placeholder col-6"></span>
          </p>
          <a
            class="btn btn-secondary disabled placeholder w-100"
            aria-disabled="true"
          ></a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="m-4 p-4 rounded-4 border border-2">
  <div class="d-flex flex-column gap-2 placeholder-glow">
    <h2 class="placeholder col-4" style="text-shadow: #000000 1px 0 10px"></h2>
    <div class="css-uewffw">
      <div class="cardFVB">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center gap-2">
            <img
              class="rounded"
              src="../../../assets/img/Fortnite_icon.webp"
              loading="lazy"
              alt=""
              width="64"
              height="64"
            />
            <div class="d-flex flex-column align-items-start">
              <span class="placeholder w-100"></span>
              <mat-chip-listbox aria-label="Dog selection">
                <mat-chip><span class="placeholder w-100"></span></mat-chip>
                <mat-chip color="primary"
                  ><span class="placeholder w-100"></span
                ></mat-chip>
              </mat-chip-listbox>
            </div>
          </div>
          <div>
            <span class="placeholder rounded-5" style="width: 32px; height: 32px;"></span>
            <span class="placeholder w-100"></span>
          </div>
        </div>
      </div>
      <div class="cardFVB">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center gap-2">
            <img
              class="rounded"
              src="../../../assets/img/Fortnite_icon.webp"
              loading="lazy"
              alt=""
              width="64"
              height="64"
            />
            <div class="d-flex flex-column align-items-start">
              <span class="placeholder w-100"></span>
              <mat-chip-listbox aria-label="Dog selection">
                <mat-chip><span class="placeholder w-100"></span></mat-chip>
                <mat-chip color="primary"
                  ><span class="placeholder w-100"></span
                ></mat-chip>
              </mat-chip-listbox>
            </div>
          </div>
          <div>
            <span class="placeholder rounded-5" style="width: 32px; height: 32px;"></span>
            <span class="placeholder w-100"></span>
          </div>
        </div>
      </div>
      <div class="cardFVB">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center gap-2">
            <img
              class="rounded"
              src="../../../assets/img/Fortnite_icon.webp"
              loading="lazy"
              alt=""
              width="64"
              height="64"
            />
            <div class="d-flex flex-column align-items-start">
              <span class="placeholder w-100"></span>
              <mat-chip-listbox aria-label="Dog selection">
                <mat-chip><span class="placeholder w-100"></span></mat-chip>
                <mat-chip color="primary"
                  ><span class="placeholder w-100"></span
                ></mat-chip>
              </mat-chip-listbox>
            </div>
          </div>
          <div>
            <span class="placeholder rounded-5" style="width: 32px; height: 32px;"></span>
            <span class="placeholder w-100"></span>
          </div>
        </div>
      </div>
      <div class="cardFVB">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center gap-2">
            <img
              class="rounded"
              src="../../../assets/img/Fortnite_icon.webp"
              loading="lazy"
              alt=""
              width="64"
              height="64"
            />
            <div class="d-flex flex-column align-items-start">
              <span class="placeholder w-100"></span>
              <mat-chip-listbox aria-label="Dog selection">
                <mat-chip><span class="placeholder w-100"></span></mat-chip>
                <mat-chip color="primary"
                  ><span class="placeholder w-100"></span
                ></mat-chip>
              </mat-chip-listbox>
            </div>
          </div>
          <div>
            <span class="placeholder rounded-5" style="width: 32px; height: 32px;"></span>
            <span class="placeholder w-100"></span>
          </div>
        </div>
      </div>
      <div class="cardFVB">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center gap-2">
            <img
              class="rounded"
              src="../../../assets/img/Fortnite_icon.webp"
              loading="lazy"
              alt=""
              width="64"
              height="64"
            />
            <div class="d-flex flex-column align-items-start">
              <span class="placeholder w-100"></span>
              <mat-chip-listbox aria-label="Dog selection">
                <mat-chip><span class="placeholder w-100"></span></mat-chip>
                <mat-chip color="primary"
                  ><span class="placeholder w-100"></span
                ></mat-chip>
              </mat-chip-listbox>
            </div>
          </div>
          <div>
            <span class="placeholder rounded-5" style="width: 32px; height: 32px;"></span>
            <span class="placeholder w-100"></span>
          </div>
        </div>
      </div>
      <div class="cardFVB">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center gap-2">
            <img
              class="rounded"
              src="../../../assets/img/Fortnite_icon.webp"
              loading="lazy"
              alt=""
              width="64"
              height="64"
            />
            <div class="d-flex flex-column align-items-start">
              <span class="placeholder w-100"></span>
              <mat-chip-listbox aria-label="Dog selection">
                <mat-chip><span class="placeholder w-100"></span></mat-chip>
                <mat-chip color="primary"
                  ><span class="placeholder w-100"></span
                ></mat-chip>
              </mat-chip-listbox>
            </div>
          </div>
          <div>
            <span class="placeholder rounded-5" style="width: 32px; height: 32px;"></span>
            <span class="placeholder w-100"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="m-4 p-4 rounded-4 border border-2">
  <h2>{{ "HOME.LIVE.TITLE" | translate }}</h2>
  <div
    class="d-flex flex-column align-items-center justify-content-between gap-3"
  >
    <svg width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        d="M391 104h-38v109h38zm-106-1h-39v110h39zM121 0 24 91v330h116v91l97-91h77l174-165V0zm328 238-77 73h-77l-68 64v-64h-87V37h309z"
        fill="var(--font)"
      />
    </svg>
    <p>{{ "HOME.LIVE.NONE" | translate }}</p>
  </div>
</section>
<footer></footer>
