// app.module.ts:
import { BrowserModule } from '@angular/platform-browser';
import { APP_ID, NgModule, ErrorHandler, Injectable, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShowOnDirtyErrorStateMatcher, ErrorStateMatcher } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthComponent } from './pages/auth/auth/auth.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { LogoutComponent } from './pages/auth/logout/logout.component';
import { ProfileOverviewComponent } from './pages/profile/profile.component';
import { SidebarComponent } from './dashboard/sidebar/sidebar.component';
import { SpinnerComponent } from './utils/spinner/spinner.component';
import { HomeComponent } from './pages/home/home.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { PrivacyComponent } from './pages/privacy-policy/privacy-policy.component';
import { MatchesComponent, DialogOverviewAccept } from './pages/matches/matches.component';
import { MatchComponent, DialogOverviewSubmitMatchResult } from './pages/match/match.component';
import { DashboardSidebarComponent } from './dashboard/dashboard/dashboard-sidebar/dashboard-sidebar.component';
import { CustomizeTeamCreateComponent } from './dashboard/dashboard/create-team-card/create-team-card.component';
import { CustomizeeTeamComponent, DialogOverviewEditTitle, DialogOverviewInvite } from './dashboard/dashboard/team-card/team-card.component';
import { CustomizeMatchComponent } from './dashboard/dashboard/match-card/match-card.component';
import { CustomizeUserComponent } from './dashboard/dashboard/user-card/user-card.component';
import { MaterialModule } from './material-module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { CleanDateTimePipe } from './pipes/clean-date-time.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { ZippyComponent } from './zippy/zippy.component';
import { MiniDatePipe } from './pipes/mini-date.pipe';
import { environment } from '../environments/environment';
import { SnakeToSentenceCasePipe } from './pipes/snake-to-sentence-case.pipe';
import { TruncatedPipe } from './pipes/truncated.pipe';
import { DurationStringPipe } from './pipes/duration-string.pipe';
import { CamelToSentenceCasePipe } from './pipes/camel-to-sentence-case.pipe';
import { WavesComponent } from './utils/waves/waves.component';
import { NavbarComponent } from './core/navbar/navbar.component';
import { FooterComponent } from './core/footer/footer.component';
import { ShopComponent } from './pages/shop/shop/shop.component';
import { ShopCardComponent } from './pages/shop/shop-card/shop-card.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable()
export class AlertErrorHandler implements ErrorHandler {
  async handleError(error: Error | any) {
    try {
      console.log(error?.rejection?.error ?? error?.message ?? error);

      const key = localStorage.getItem('key');
      await fetch(`${environment.endpoint}/error?key=${key}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: error.message })
      });
    } finally {
      console.log(error);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    LeaderboardComponent,
    PrivacyComponent,
    AuthComponent,
    LoginComponent,
    LogoutComponent,
    ProfileOverviewComponent,
    SidebarComponent,
    SpinnerComponent,
    MatchesComponent,
    DialogOverviewAccept,
    MatchComponent,
    DialogOverviewSubmitMatchResult,
    CustomizeTeamCreateComponent,
    CustomizeMatchComponent,
    CustomizeUserComponent,
    DashboardSidebarComponent,
    NotFoundComponent,
    PaymentSuccessComponent,
    ShopComponent,
    ShopCardComponent,
    CleanDateTimePipe,
    TimeFormatPipe,
    ZippyComponent,
    MiniDatePipe,
    SnakeToSentenceCasePipe,
    TruncatedPipe,
    DurationStringPipe,
    CamelToSentenceCasePipe,
    CustomizeeTeamComponent,
    DialogOverviewEditTitle,
    DialogOverviewInvite,
    WavesComponent,
    SafeHtmlPipe
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgOptimizedImage,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MaterialModule
  ],
  providers: [
    { provide: APP_ID, useValue: 'serverApp' },
    { provide: ErrorHandler, useClass: AlertErrorHandler },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
