import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { User, UserService } from '../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { WebSocketService } from '../../../services/websocket.service';
import { WebSocketEvents, WebSocketMessage } from '../../../services/websocket.interface';

@Component({
  selector: 'user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.css']
})
export class CustomizeUserComponent implements OnInit {
  @Output() userCard = new EventEmitter<void>(); // Emite sin parámetros

  epicId = new FormControl('', Validators.required);
  username = new FormControl('', Validators.required);
  avatar = new FormControl('', Validators.required);
  decorator = new FormControl('', Validators.required);
  coins: number;
  inventory: any;

  get user() { return this.userService.user ?? {}; }
  get savedUser() { return this.userService.savedUser ?? {}; }

  constructor(
    public translate: TranslateService,
    private userService: UserService,
    private webSocketService: WebSocketService
  ) { }

  ngOnInit() {
    this.webSocketService.subscribeToUpdates(WebSocketEvents.USER_UPDATED, { userId: this.user.id });
    this.webSocketService.listenForUpdates(WebSocketEvents.USER_UPDATED).subscribe({
      next: (message: WebSocketMessage) => {
        if (message.event === WebSocketEvents.USER_UPDATED) {
          this.updateUser(message.data.updatedUser);
        }
      },
      error: (error) => {
        console.error(error);
      }
    });

    this.userService.init().catch(error => {
      console.error(error);
    });

    this.updateForm(this.savedUser);
  }

  updateUser(updatedUser: User) {
    this.avatar.patchValue(updatedUser.avatar);
    this.decorator.patchValue(updatedUser.decorator);
    this.username.patchValue(updatedUser.username);
    this.epicId.patchValue(updatedUser.epicId);
    this.coins = updatedUser.coins;
    this.inventory = updatedUser.inventory;
  }

  updateForm(user: User) {
    this.epicId.patchValue(user.epicId ?? '');
    this.username.patchValue(user.username ?? '');
    this.avatar.patchValue(user.avatar ?? '');
    this.decorator.patchValue(user.decorator ?? '');
  }

  submitUser() {
    const updatedUserData: User = {
      _id: this.user._id,
      epicId: this.epicId.value,
      username: this.username.value,
      avatar: this.avatar.value,
      decorator: this.decorator.value,
      coins: this.coins,
      inventory: this.inventory
    };

    // Emitir el evento de actualización del usuario a través del WebSocket
    this.webSocketService.emitUserUpdates(WebSocketEvents.USER_UPDATED, this.user.id, updatedUserData); // Usa el evento correcto
    this.userCard.emit(); // Emite el evento para actualizar el card
  }
}