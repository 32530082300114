import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyComponent {
  privacy = 'privacy-policy';
  version = environment.version;
  anchorVersion = environment.version.replace(/\./g, '');
  CurrentYear = new Date().getFullYear();

  constructor(public translate: TranslateService) {
    document.title = 'BeatTheAura - Social Token Platform';
  }
}
