import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  endpoint = environment.endpoint;

  get key() { return localStorage.getItem('key'); }

  constructor(private http: HttpClient) { }

  async init() {
  }

  async getUsersIds(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.endpoint}/users`));
  }

  async getUser(userId: string): Promise<UserSavedUser> {
    try {
      return firstValueFrom(this.http.get<UserSavedUser>(`${this.endpoint}/users/user/${userId}`));
    } catch (error) {
      console.error('Error obteniendo el usuario', error);
      return null; // Manejar el error apropiadamente
    }
  }

  async searchUsersByName(name: string): Promise<SavedUser[]> {
    try {
      return firstValueFrom(this.http.get<SavedUser[]>(`${this.endpoint}/users/search?name=${name}`));
    } catch (error) {
      console.error('Error searching users by name', error);
      return []; // Manejar el error apropiadamente
    }
  }

  async updateUserCoins(userId: string, coins: number) {
    return firstValueFrom(this.http.put(`${this.endpoint}/users/user/${userId}/coins`, { coins }));
  }

}

export interface UserSavedUser {
  savedUser: SavedUser;
}

export interface SavedUser {
  _id: string;
  epicId?: string;
  coins?: number;
  username?: string;
  avatar?: string;
}