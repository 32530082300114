<div
  class="d-flex flex-wrap justify-content-around p-4 gap-2"
  style="background-color: var(--background-tertiary)"
>
  <div style="max-width: 80rem; width: 100%">
    <div class="mt-6 px-4 py-6">
      <div class="d-flex flex-wrap justify-content-between">
        <ul class="nav flex-column">
          <li class="nav-item">
            <h3 style="color: var(--button-primary)">
              {{ "HOME.FOOTER.POLITYC.TITLE" | translate }}
            </h3>
          </li>
          <li class="nav-item">
            <a
              class="text-decoration-none"
              routerLink="/privacy-policy"
              routerLinkActive="active"
            >
              <span style="color: var(--muted)">{{
                "HOME.FOOTER.POLITYC.PRIVACY" | translate
              }}</span>
            </a>
          </li>
        </ul>
        <ul class="nav flex-column">
          <li class="nav-item">
            <h3 style="color: var(--button-primary)">LINKS</h3>
          </li>
          <li class="nav-item">
            <a
              class="text-decoration-none"
              routerLink="/shop"
              routerLinkActive="active"
            >
              <span style="color: var(--button-secondary) !important">{{
                "DASHBOARD.SHOP" | translate
              }}</span>
            </a>
          </li>
        </ul>
        <ul class="nav flex-column">
          <li class="nav-item">
            <h3 style="color: var(--button-primary)">BeatTheAura</h3>
          </li>
          <li class="nav-item">
            <a
              class="text-decoration-none"
              [fragment]="anchorVersion"
            >
              <span style="color: var(--muted)">{{ version }}</span>
            </a>
          </li>
        </ul>
      </div>
      <hr />
      <div class="d-flex justify-content-center">
        <ul class="nav flex-column">
          <li class="nav-item">
            <span style="color: var(--muted)"
              >Copyright &copy; 2020 - {{ CurrentYear }} BeatTheAura</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
