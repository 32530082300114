<div class="modal-dialog" role="document">
  <div class="modal-content">
    <form #f="ngForm" [formGroup]="form">
      <div class="modal-header">
        <h5 class="modal-title">{{ "TEAM.CREATE.TITLE" | translate }}</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
        ></button>
      </div>
      <div class="modal-body">
        <div class="pb-0">
          <label>{{ "TEAM.CREATE.NAME.TITLE" | translate }}</label>
          <input
            class="p-2 w-100 border-0 rounded text-font bg-tertiary"
            formControlName="name"
            type="text"
            placeholder="Team {{ user.username }}"
            formControlName="name"
          />
          <div *ngIf="form.invalid && form.get('name')?.errors?.['required']">
            {{ "TEAM.CREATE.NAME.ERROR" | translate }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{ "MODAL.CLOSE" | translate }}
        </button>
        <button
          [disabled]="form.invalid"
          class="btn btn-bd-primary"
          (click)="createTeam()"
        >
          {{ "TEAM.CREATE.TITLE" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
