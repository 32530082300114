// src/app/services/ws.service.ts

import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { WebSocketEvents, WebSocketMessage } from './websocket.interface';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket$: WebSocketSubject<WebSocketMessage>;

  constructor() {
    // Cambia la URL a tu servidor WebSocket se modifica en caso de producción
    this.socket$ = webSocket(`wss://beattheaura.espcustoms.xyz/ws/xx`);
  }

  private sendMessage(message: WebSocketMessage) {
    this.socket$.next(message);
  }

  // Método general para suscribirse a cualquier evento con matchId o userId opcionales
  subscribeToUpdates(event: string, options: { matchId?: string, userId?: string } = {}) {
    const data = { ...options };
    this.sendMessage({ event, data });
  }


  // Método general para escuchar cualquier evento
  listenForUpdates(event: string, filterFn?: (message: WebSocketMessage) => boolean): Observable<WebSocketMessage> {
    return this.socket$.asObservable().pipe(
      filter((message) => message.event === event && (!filterFn || filterFn(message)))
    );
  }

  emitMatchUpdates(event: string, matchId: string, data: any) {
    this.socket$.next({ event, data: { ...data, matchId } });
  }

  // Método para enviar mensajes al servidor WebSocket de actualización de usuario
  emitUserUpdates(event: string, userId: string, data: any) {
    this.socket$.next({ event, data: { ...data, userId } });
  }
}
