import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { ApiService, SavedUser } from '../../../services/api.service';
import { TeamCard, TeamService } from '../../../services/team.service';

export interface DialogData {
  teamId: string,
  teamName: string,
  teamMembers: string[],
  teamStats: {
    earnings: number;
    played: number;
    wins: number;
    losses: number;
  }
}

@Component({
  selector: 'team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.css']
})
export class CustomizeeTeamComponent implements OnInit {
  @Output() teamCard = new EventEmitter();

  ModalTeamCard = 'roster' ? 'stats' : 'roster';

  teamMemberDetails: { id: string, name: string, avatar: string }[] = [];

  get user() { return this.userService.user ?? {}; }

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<CustomizeeTeamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private userService: UserService,
    private apiService: ApiService,
    private teamService: TeamService
  ) { }

  async ngOnInit() {
    await this.loadTeamMembers();
  }

  async loadTeamMembers() {
    try {
      const team = await this.teamService.getTeam(this.data.teamId) as TeamCard;
      const userIds = team.teamMembers.map(id => id);
      const userPromises = userIds.map(userId => this.apiService.getUser(userId));
      const userData = await Promise.all(userPromises);

      this.teamMemberDetails = userData.map(user => ({
        id: user.savedUser._id,
        name: user.savedUser.username,
        avatar: user.savedUser.avatar
      }));
    } catch (error) {
      console.error('Error loading team members:', error);
    }
  }

  editName(): void {
    const dialogRef = this.dialog.open(DialogOverviewEditTitle, {
      data: { teamId: this.data.teamId, teamName: this.data.teamName, teamMembers: this.data.teamMembers, teamStats: this.data.teamStats },
    });

    dialogRef.afterClosed().subscribe(result => result);
  }

  removeMember(index: number): void {
    // No se permite eliminar el primer miembro (índice 0)
    if (index !== 0) {
      // Elimina el miembro del arreglo
      this.teamMemberDetails.splice(index, 1);

      // Actualiza el arreglo de miembros del equipo
      const teamCard: TeamCard = {
        _id: this.data.teamId,
        name: this.data.teamName,
        teamMembers: this.teamMemberDetails.map(member => member.id),
        stats: {
          earnings: this.data.teamStats.earnings,
          played: this.data.teamStats.played,
          wins: this.data.teamStats.wins,
          losses: this.data.teamStats.losses
        }
      };

      this.teamService.sendTeamCard(teamCard);
      window.location.reload();
    }
  }
  invite(): void {
    const dialogRef = this.dialog.open(DialogOverviewInvite, {
      data: { teamId: this.data.teamId, teamName: this.data.teamName, teamMembers: this.data.teamMembers, teamStats: this.data.teamStats },
    });

    dialogRef.afterClosed().subscribe(result => result);
  }
}

@Component({
  selector: 'dialog-overview-edit-title',
  templateUrl: 'dialog-overview-edit-title.html'
})
export class DialogOverviewEditTitle implements OnInit {

  get user() { return this.userService.user; }

  name = new FormControl('', [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewEditTitle>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService: UserService,
    private teamService: TeamService
  ) { }

  async ngOnInit() {
    this.name.patchValue(this.data.teamName ?? '');
  }

  async cancel() {
    window.location.reload();
  }

  editTeam() {
    const teamCard: TeamCard = {
      _id: this.data.teamId,
      name: this.name.value,
      teamMembers: this.data.teamMembers,
      stats: {
        earnings: this.data.teamStats.earnings,
        played: this.data.teamStats.played,
        wins: this.data.teamStats.wins,
        losses: this.data.teamStats.losses
      }
    };

    this.teamService.sendTeamCard(teamCard);
    window.location.reload();
  }
}

@Component({
  selector: 'dialog-overview-invite',
  templateUrl: 'dialog-overview-invite.html'
})
export class DialogOverviewInvite implements OnInit {
  get user() {
    return this.userService.user;
  }
  searchQuery = new FormControl('');
  foundUsers: SavedUser[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewInvite>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService: UserService,
    private teamService: TeamService,
    private apiService: ApiService
  ) { }

  async ngOnInit() {
    this.searchQuery.valueChanges.subscribe(() => this.searchUsers());
  }

  async searchUsers() {
    const query = this.searchQuery.value.trim();
    if (query.length < 3) {
      return;
    }

    try {
      this.foundUsers = await this.apiService.searchUsersByName(query);
    } catch (error) {
      console.error('Error searching users:', error);
    }
  }

  async addUserToTeam(userId: string) {
    this.data.teamMembers.push(userId);
    const teamCard: TeamCard = {
      _id: this.data.teamId,
      name: this.data.teamName,
      teamMembers: this.data.teamMembers,
      stats: {
        earnings: this.data.teamStats.earnings,
        played: this.data.teamStats.played,
        wins: this.data.teamStats.wins,
        losses: this.data.teamStats.losses
      }
    };

    this.teamService.sendTeamCard(teamCard);
    window.location.reload();
  }
}