import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatchCard, MatchService } from '../../services/match.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WebSocketService } from '../../services/websocket.service';
import { amount } from '../../utils';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { TeamCard, TeamService } from '../../services/team.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WebSocketEvents, WebSocketMessage } from '../../services/websocket.interface';

export interface DialogData {
  matchComponent: MatchComponent;
  _id: string;
}

@Component({
  selector: 'app-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.css']
})
export class MatchComponent implements OnInit, OnDestroy {

  timeRemaining: number;
  intervalId: any;
  status: string;
  match: MatchCard;

  teamHostUsers: any[] = [];
  teamJoinUsers: any[] = [];

  amount = amount;
  endTime: number;

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    private userService: UserService,
    private apiService: ApiService,
    private matchService: MatchService,
    private route: ActivatedRoute,
    private router: Router,
    private webSocketService: WebSocketService
  ) { }

  get user() { return this.userService.user; }
  get savedUser() { return this.userService.savedUser; }

  private destroy$ = new Subject<void>();

  async ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(async (paramMap) => {
      const id = paramMap.get('id');
      this.webSocketService.subscribeToUpdates(WebSocketEvents.MATCH_UPDATED, { matchId: id });
      this.webSocketService.listenForUpdates(WebSocketEvents.MATCH_UPDATED).subscribe({
        next: async (message: WebSocketMessage) => {
          console.log(message);
          if (message.data._id === id) {
            this.match = message.data;
            this.status = this.match.status;

            await this.loadTeamMembers();

            this.endTime = new Date(this.match.endTime).getTime();

            // Borrar el intervalo existente antes de crear uno nuevo
            if (this.intervalId) {
              clearInterval(this.intervalId);
            }

            if (this.match.teamJoin) {
              const userPromises = this.match.teamJoin.teamMembers.map(userId => this.apiService.getUser(userId));
              this.teamJoinUsers = await Promise.all(userPromises);
            }

            this.intervalId = setInterval(async () => {
              this.timeRemaining = Math.floor((this.endTime - Date.now()) / 1000);
              if (this.timeRemaining <= 0) {
                clearInterval(this.intervalId);
                this.router.navigate([`matches`]);
                await this.matchService.delMatch(id);
              }
            }, 1000);
          }
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  async loadTeamMembers() {
    const userPromises = this.match.teamHost.teamMembers.map(userId => this.apiService.getUser(userId));
    try {
      this.teamHostUsers = await Promise.all(userPromises);
    } catch (error) {
      console.error(error);
    }
  }

  checkIconTeam(team: 'teamHost' | 'teamJoin') {
    // Manejar el caso en que match o submitresult sea nulo
    if (!this.match || !this.match.submitresult) {
      return 'none';
    }

    const teamId = this.match[team]._id; // Asumiendo que la ID del equipo se almacena en _id

    // Verificar si el equipo es el ganador
    if (Array.isArray(this.match.submitresult.winner) && this.match.submitresult.winner.includes(teamId)) {
      return 'winner';
    }

    // Verificar si el equipo es el perdedor
    if (Array.isArray(this.match.submitresult.loser) && this.match.submitresult.loser.includes(teamId)) {
      return 'loser';
    }

    // Si el equipo no es ni ganador ni perdedor, retornar 'none'
    return 'none';
  }

  userBelongsToTeam() {
    if (this.match.teamHost.teamMembers.includes(this.user.id) || this.match.teamJoin.teamMembers.includes(this.user.id))
      return (true)
    else
      return (false);
  }

  async startDisputedStatusCheck(matchId: string, winner: TeamCard): Promise<void> {
    console.log('Starting disputed status check...');
    this.webSocketService.subscribeToUpdates(WebSocketEvents.MATCH_UPDATED, { matchId: matchId });

    this.webSocketService.listenForUpdates(WebSocketEvents.MATCH_UPDATED).subscribe({
      next: async (message: WebSocketMessage) => {
        console.log(message);
        try {
          if (message.data.matchUpdate.status === 'bothTeamsWon') {
            console.log('Match result disputed');

            // Actualiza el tiempo de finalización y emite un evento MATCH_UPDATED
            this.endTime = Date.now() + 24 * 60 * 60 * 1000;
            const data = {
              submitresult: { winner: [winner._id] },
              status: 'bothTeamsWon',
              endTime: new Date(this.endTime)
            };
            // Emitir el evento MATCH_UPDATED
            this.webSocketService.emitMatchUpdates(WebSocketEvents.MATCH_UPDATED, matchId, data);
            this.timeRemaining = Math.floor((this.endTime - Date.now()) / 1000);

          } else if (message.data.matchUpdate.status === 'teamHostWon' || message.data.matchUpdate.status === 'teamJoinWon') {
            console.log('Match result submitted and coins updated');

            // Actualiza el tiempo de finalización y emite un evento MATCH_UPDATED
            this.endTime = Date.now() + 3 * 60 * 1000;
            this.timeRemaining = Math.floor((this.endTime - Date.now()) / 1000);

            const data = {
              submitresult: { winner: [winner._id] },
              status: 'finished',
              endTime: new Date(this.endTime + 24 * 60 * 60 * 1000) // Ajusta el tiempo de finalización aquí si es necesario
            };
            // Emitir el evento MATCH_UPDATED
            this.webSocketService.emitMatchUpdates(WebSocketEvents.MATCH_UPDATED, matchId, data);

            const coinsToSubtract = message.data.matchUpdate.coins as number;
            await Promise.all(winner.teamMembers.map(async userId => {
              const user = await this.apiService.getUser(userId);
              if (user) {
                const updatedCoins = user.savedUser.coins + amount(coinsToSubtract);
                await this.apiService.updateUserCoins(userId, updatedCoins);
              }
            }));

          } else if (this.timeRemaining <= 0) {
            console.log('Time expired, match result disputed');

            // Actualiza el tiempo de finalización y emite un evento MATCH_UPDATED
            this.endTime = Date.now() + 24 * 60 * 60 * 1000;
            const data = {
              submitresult: { winner: [winner._id] },
              status: 'bothTeamsWon',
              endTime: new Date(this.endTime)
            };
            // Emitir el evento MATCH_UPDATED
            this.webSocketService.emitMatchUpdates(WebSocketEvents.MATCH_UPDATED, matchId, data);
            this.timeRemaining = Math.floor((this.endTime - Date.now()) / 1000); // Actualizar timeRemaining en función del nuevo endTime
          }
        } catch (error) {
          console.error('Error in disputed status check:', error);
        }
      }
    });
  }

  openDialog(_id: string): void {
    const dialogRef = this.dialog.open(DialogOverviewSubmitMatchResult, {
      data: { matchComponent: this, _id: _id},
    });

    dialogRef.afterClosed().subscribe(result => result);
  }
}
@Component({
  selector: 'dialog-overview-submit-match-result',
  templateUrl: 'dialog-overview-submit-match-result.html',
  styleUrls: ['./match.component.css']
})
export class DialogOverviewSubmitMatchResult {
  enabled: boolean = false;
  status: string;
  match: MatchCard;
  teams: TeamCard[] = [];
  get user() { return this.userService.user; }
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewSubmitMatchResult>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService: UserService,
    private matchService: MatchService,
    private apiService: ApiService,
    private teamService: TeamService,
    private webSocketService: WebSocketService
  ) {
    this.status = this.data.matchComponent.status;
  }

  async TeamLost(): Promise<void> {
    const match = await this.matchService.getMatch(this.data._id) as MatchCard;
    const userTeamId = this.user.id;
    const isUserInTeamHost = match.teamHost.teamMembers.includes(userTeamId);
    const isUserInTeamJoin = match.teamJoin.teamMembers.includes(userTeamId);
    if (isUserInTeamHost) {
      const winner = match.teamJoin;
      const loser = match.teamHost;
      await this.sendMatchSubmitAndUpdateStats(match.coins, winner, loser);
      await Promise.all(winner.teamMembers.map(async userId => {
        const user = await this.apiService.getUser(userId);
        if (user) {
          const updatedCoins = user.savedUser.coins + amount(match.coins);
          console.log(`Updating user ${userId} with ${match.coins} coins. Total coins: ${updatedCoins}`);
          await this.apiService.updateUserCoins(userId, updatedCoins);
        }
      }));
    } else if (isUserInTeamJoin) {
      const winner = match.teamHost;
      const loser = match.teamJoin;
      await this.sendMatchSubmitAndUpdateStats(match.coins, winner, loser);
      await Promise.all(winner.teamMembers.map(async userId => {
        const user = await this.apiService.getUser(userId);
        if (user) {
          const updatedCoins = user.savedUser.coins + amount(match.coins);
          console.log(`Updating user ${userId} with ${match.coins} coins. Total coins: ${updatedCoins}`);
          await this.apiService.updateUserCoins(userId, updatedCoins);
        }
      }));
    }
    this.dialogRef.close();
  }
  async TeamWon(): Promise<void> {
    const match = await this.matchService.getMatch(this.data._id) as MatchCard;
    const userTeamId = this.user.id;
    const isUserInTeamHost = match.teamHost.teamMembers.includes(userTeamId);
    const isUserInTeamJoin = match.teamJoin.teamMembers.includes(userTeamId);
    this.webSocketService.subscribeToUpdates(WebSocketEvents.MATCH_UPDATED, { matchId: this.data._id });

    if (isUserInTeamHost) {
      const winner = match.teamHost;
      match.status = match.status === 'teamJoinWon' ? 'bothTeamsWon' : 'teamHostWon';

      // Añadir la ID del equipo a submitresult.winner
      const winners = match.submitresult && match.submitresult.winner ? [...match.submitresult.winner, winner._id] : [winner._id];

      const data = { submitresult: { winner: winners }, status: match.status, endTime: new Date(new Date().getTime() + 3 * 60 * 1000) };
      // Emitir el evento MATCH_UPDATED
      this.webSocketService.emitMatchUpdates(WebSocketEvents.MATCH_UPDATED, this.data._id, data);
      this.data.matchComponent.startDisputedStatusCheck(this.data._id, match.teamHost);
    } else if (isUserInTeamJoin) {
      const winner = match.teamJoin;
      match.status = match.status === 'teamHostWon' ? 'bothTeamsWon' : 'teamJoinWon';

      // Añadir la ID del equipo a submitresult.winner
      const winners = match.submitresult && match.submitresult.winner ? [...match.submitresult.winner, winner._id] : [winner._id];

      const data = { submitresult: { winner: winners }, status: match.status, endTime: new Date(new Date().getTime() + 3 * 60 * 1000) };
      // Emitir el evento MATCH_UPDATED
      this.webSocketService.emitMatchUpdates(WebSocketEvents.MATCH_UPDATED, this.data._id, data);
      this.data.matchComponent.startDisputedStatusCheck(this.data._id, match.teamJoin);
    }
    this.dialogRef.close();
  }
  private async sendMatchSubmitAndUpdateStats(coins: number, winner: TeamCard, loser: TeamCard): Promise<void> {
    const data = {
      submitresult: { winner: [winner._id], loser: [loser._id] },
      status: 'finished',
      endTime: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    }
    this.webSocketService.subscribeToUpdates(WebSocketEvents.MATCH_UPDATED, { matchId: this.data._id });
    // Emitir el evento MATCH_UPDATED
    this.webSocketService.emitMatchUpdates(WebSocketEvents.MATCH_UPDATED, this.data._id, data);
    await this.updateTeamStatsForWinners(coins, winner);
    await this.updateTeamStatsForLosers(loser);
  }

  private async updateTeamStatsForWinners(coins: number, winner: TeamCard): Promise<void> {
    console.log(`Winner:${winner._id}`);
    winner.stats.wins += 1;
    winner.stats.played += 1;
    winner.stats.earnings += coins;
    await this.teamService.updateTeamStats(winner._id, {stats: winner.stats});
  }
  private async updateTeamStatsForLosers(loser: TeamCard): Promise<void> {
    console.log(`Looser:${loser._id}`);
    loser.stats.losses += 1;
    loser.stats.played += 1;
    await this.teamService.updateTeamStats(loser._id, {stats: loser.stats});
  }
}