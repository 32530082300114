import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CustomizeeTeamComponent } from '../team-card/team-card.component';
import { MatDialog } from '@angular/material/dialog';
import { ThemeService } from '../../../services/theme.service';
import { TeamService } from '../../../services/team.service';
import { CustomizeTeamCreateComponent } from '../create-team-card/create-team-card.component';

@Component({
  selector: 'dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.css']
})
export class DashboardSidebarComponent implements OnInit {
  @ViewChild('themeSelect') themeSelect: MatSelect;
  @ViewChild('langSelect') langSelect: MatSelect;

  defaultTheme = 'DEFAULT';

  teams = [];

  themeControl = new FormControl(localStorage.getItem('theme') ?? this.defaultTheme);

  get user() { return this.userService.user ?? {}; }
  get savedUser() { return this.userService.savedUser; }

  BannerURL = this.user.bannerURL

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    public service: ThemeService,
    private userService: UserService,
    private teamService: TeamService
  ) {
    document.title = 'BeatTheAura - Dashboard';
    this.service.updateTheme();
  }
  async ngOnInit() {
    if (this.user.bannerURL === "https://cdn.discordapp.com/embed/banners/0.png?size=1024") {
      this.BannerURL = "https://i.imgur.com/Q5hLYaW.gif"
    }
    this.themeControl.valueChanges.subscribe(theme => {
      this.service.changeTheme(theme);
    });
    this.loadUserTeams();
  }

  async loadUserTeams() {
    try {
      const userTeams = await this.teamService.getUserTeams(this.user.id);
      this.teams = userTeams;
    } catch (error) {
      console.error('Error loading user teams:', error);
    }
  }

  openDialog(teamId: string, teamName: string, teamMembers: string[], earnings: string, played: string, wins: string, losses: string): void {
    const dialogRef = this.dialog.open(CustomizeeTeamComponent, {
      data: {
        teamId, teamName, teamMembers, teamStats: {
          earnings: earnings,
          played: played,
          wins: wins,
          losses: losses,
        }
      },
    });

    dialogRef.afterClosed().subscribe(result => result);
  }

  onCreateTeam() {
    const dialogRef = this.dialog.open(CustomizeTeamCreateComponent);

    dialogRef.afterClosed();
  }
}