import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  endpoint = environment.endpoint + '/shop';

  private _decorators: any;
  get decorators() {
    return this._decorators;
  }

  constructor(private http: HttpClient) { }

  private get key() {
    return localStorage.getItem('key');
  }

  async init() {
    if (!this.decorators)
      await this.updateDecorators();
  }

  async updateDecorators() {
    this._decorators = await firstValueFrom(this.http.get(`${this.endpoint}/decorators`));
  }

  shopDecorator(decorator: string) {
    return `${this.endpoint}/?key=${this.key}&decorator=${decorator}`;
  }
}