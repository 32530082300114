import { Component, OnInit, Output, EventEmitter, Renderer2, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TeamCard, TeamService } from '../../../services/team.service';
import { amount, generateRandomNumericCode } from '../../../utils';
import { MatchCard, MatchService } from '../../../services/match.service';
import { ApiService } from '../../../services/api.service';

interface GameMode {
  name: string;
  value: string;
}

interface BestOfs {
  name: string;
  value: number;
}

interface TeamSize {
  name: string;
  value: string;
}

@Component({
  selector: 'match-card',
  templateUrl: './match-card.component.html',
  styleUrls: ['./match-card.component.css']
})
export class CustomizeMatchComponent implements OnInit {
  @Output() matchCard = new EventEmitter();

  userTeams: TeamCard[] = []; // Agrega esta variable para almacenar los equipos del usuario
  selectedTeam: TeamCard;

  amount = amount;

  gamemodes: GameMode[] = [
    { name: 'ZoneWars', value: "ZoneWars" },
    { name: 'Realistic', value: "Realistic" },
    { name: 'BoxFights', value: "BoxFights" },
    { name: 'ZeroBuild Realistic', value: "ZeroBuild Realistic" },
    { name: 'ZeroBuild BoxFights', value: "ZeroBuild BoxFights" },
  ];

  bestofs: BestOfs[] = [
    { name: '3', value: 3 },
    { name: '5', value: 5 },
  ];

  teamsizes: TeamSize[] = [
    { name: '1v1', value: "1v1" },
    { name: '2v2', value: "2v2" },
    { name: '3v3', value: "3v3" },
    { name: '4v4', value: "4v4" }
  ];

  teams: TeamCard[] = [];

  form = new FormGroup({
    _id: new FormControl(`${generateRandomNumericCode(18)}`),
    mode: new FormControl<GameMode>(null, [Validators.required]),
    bestOf: new FormControl<BestOfs>(null, [Validators.required]),
    teamSize: new FormControl<TeamSize>(null, [Validators.required]),
    teamHost: new FormGroup({
      _id: new FormControl(''),
      name: new FormControl(''),
      teamMembers: new FormControl<string[]>([]),
      stats: new FormGroup({
        earnings: new FormControl(0),
        played: new FormControl(0),
        wins: new FormControl(0),
        losses: new FormControl(0),
      }),
    }),
    coins: new FormControl(0, [Validators.required, Validators.min(0.50)]),
    startTime: new FormControl(new Date().getTime()),
    endTime: new FormControl(new Date(new Date().getTime() + 30 * 60 * 1000)),
    status: new FormControl('open')
  });

  get user() { return this.userService.user; }

  coinstest = this.form.controls?.coins;

  constructor(
    public translate: TranslateService,
    private userService: UserService,
    private matchService: MatchService,
    private router: Router,
    private teamService: TeamService,
    private apiService: ApiService,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }
  
  async loadUserTeams() {
    try {
      const userTeams = await this.teamService.getUserTeams(this.user.id);
      this.userTeams = userTeams;
    } catch (error) {
      console.error('Error loading user teams:', error);
    }
  }

  async ngOnInit() {
    this.loadUserTeams();
  }

  async filterTeams(event: any) {
    const selectedTeamSize = event.target.value;
    const teamSizeValue = parseInt(selectedTeamSize.split('v')[0]);

    // Filtra los equipos basados en la longitud de teamMembers dentro del FormGroup teamHost
    this.teams = this.userTeams.filter(team => {
      return team.teamMembers.length === teamSizeValue;
    });
    const teams = this.el.nativeElement.querySelector('.teams');
    this.renderer.removeAttribute(teams, 'disabled');
  }

  onTeamSelectionChange(selectedTeam: TeamCard): void {
    // Actualiza el FormGroup "teamHost" con los datos del equipo seleccionado
    const teamHostFormGroup = this.form.get('teamHost');
    if (teamHostFormGroup) {
      teamHostFormGroup.patchValue({
        _id: selectedTeam._id,
        name: selectedTeam.name,
        teamMembers: selectedTeam.teamMembers,
        stats: {
          earnings: selectedTeam.stats.earnings,
          played: selectedTeam.stats.played,
          wins: selectedTeam.stats.wins,
          losses: selectedTeam.stats.losses
        }
      });
    }
  }

  async submitMatch(form: MatchCard) {
    if (this.form.invalid) {
      return alert('Formulario inválido');
    }

    const selectedTeamMembers = this.form.value.teamHost.teamMembers;
    let notEnoughCoins = false; // Bandera para rastrear si no hay suficientes monedas

    for (const userId of selectedTeamMembers) {
      const user = await this.apiService.getUser(userId);
      if (!user || user.savedUser.coins < form.coins) {
        notEnoughCoins = true;
        break; // Detener el bucle si no hay suficientes monedas para un usuario
      }
    }

    if (notEnoughCoins) {
      alert('No tienes suficientes monedas para crear este partido');
      window.location.reload(); // Recargar la página después de mostrar la alerta
      return;
    }
    // Si todas las comprobaciones pasan, crear el partido
    await this.matchService.sendMatchCard(form);

    this.matchCard.emit();
    this.router.navigate([`matches/match/${form._id}`]);
  }
}