<navbar id="homeNavbar"></navbar>
<header>
  <div>
    <div class="container">
      <h2>{{ "PRIVATE-POLICY.TITLE" | translate }}</h2>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-1" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-2" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-3" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-4" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-5" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-6" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-7" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-8" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-9" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-10" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-11" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-12" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-13" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-14" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-15" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-16" | translate }}</p>
      <p></p>
      <p>{{ "PRIVATE-POLICY.PARAGRAPH-17" | translate }}</p>
    </div>
  </div>
</header>
<footer></footer>