<dashboard-sidebar>
  <section class="container">
    <div
      class="d-flex justify-content-center align-items-center flex-wrap gap-4 pt-4"
    >
      <div class="w-100">
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <div>
            <div
              class="d-flex justify-content-between align-items-center flex-column"
            >
              <div class="me-auto">
                <h2>Matches</h2>
              </div>
              <div>
                <span
                  >{{ cardMatchesIndicesOpen.length }}
                  {{ "MATCHES.OPEN" | translate }} •
                  {{ cardMatchesIndicesOnGoing.length }}
                  {{ "MATCHES.ONGOING" | translate }}</span
                >
              </div>
            </div>
          </div>
          <button
            data-bs-toggle="modal"
            class="btn btn-bd-primary"
            data-bs-target="#customize"
          >
            {{ "MATCH.CREATE" | translate }}
          </button>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex justify-content-center align-items-center flex-wrap">
          <div
            class="btn-group d-flex justify-content-center align-content-center p-1"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            <input
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio1"
              (click)="buttonClicked = 'open'"
              value="open"
              checked
            />
            <label
              class="btn-toggle p-2 cursor-pointer w-auto"
              for="btnradio1"
              >{{ "MATCHES.OPEN" | translate }}</label
            >

            <input
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio2"
              (click)="buttonClicked = 'ongoing'"
              value="ongoing"
            />
            <label
              class="btn-toggle p-2 cursor-pointer w-auto"
              for="btnradio2"
              >{{ "MATCHES.ONGOING" | translate }}</label
            >
            <input
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio3"
              (click)="buttonClicked = 'finished'"
              value="finished"
            />
            <label
              class="btn-toggle p-2 cursor-pointer w-auto"
              for="btnradio3"
              >{{ "MATCHES.FINISHED" | translate }}</label
            >
          </div>
        </div>
      </div>
      <div class="w-100">
        <div *ngIf="buttonClicked === 'open'" class="w-100">
          <div class="w-100">
            <div
              class="d-flex justify-content-center align-items-center flex-wrap"
            >
              <div class="w-100">
                <div
                  *ngIf="!(allMatchesOpen && allMatchesOpen.length > 0)"
                  class="d-flex justify-content-between align-items-center flex-column"
                >
                  <h1>{{ "MATCHES.NO-MATCHES.TITLE" | translate }}</h1>
                  <span>{{ "MATCHES.NO-MATCHES.PARAGRAPH" | translate }}</span>
                </div>
                <div *ngIf="allMatchesOpen">
                  <div class="css-uewffw">
                    <div *ngFor="let i of cardMatchesIndicesOpen">
                      <div
                        *ngIf="
                          allMatchesOpen[i]?.teamHost.teamMembers.includes(
                            user.id
                          )
                        "
                        class="cardFVB"
                        (click)="openMatch(allMatchesOpen[i]?._id)"
                      >
                        <div class="d-flex justify-content-between">
                          <div class="d-flex align-items-center gap-2">
                            <img
                              class="rounded"
                              src="../../../assets/img/Fortnite_icon.webp"
                              loading="lazy"
                              alt=""
                              width="64"
                              height="64"
                            />
                            <div class="d-flex flex-column align-items-start">
                              <span>{{ allMatchesOpen[i]?.teamSize }}</span>
                              <mat-chip-listbox aria-label="Dog selection">
                                <mat-chip>
                                  {{ allMatchesOpen[i]?.mode }}
                                </mat-chip>
                                <mat-chip color="primary">
                                  {{ "MATCH.BESTOF.TITLE" | translate }}
                                  {{ allMatchesOpen[i]?.bestOf }}
                                </mat-chip>
                              </mat-chip-listbox>
                            </div>
                          </div>
                          <div>
                            <img
                              src="assets/img/Coin.svg"
                              alt="BeatTheAura"
                              width="32px"
                              height="32px"
                              class="mw-100 rounded-5"
                              style="aspect-ratio: 1/1"
                            />
                            {{ allMatchesOpen[i]?.coins }}
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="
                          !allMatchesOpen[i]?.teamHost.teamMembers.includes(
                            user.id
                          )
                        "
                        class="cardFVB"
                        (click)="
                          openDialog(
                            allMatchesOpen[i]?._id,
                            allMatchesOpen[i]?.mode,
                            allMatchesOpen[i]?.bestOf,
                            allMatchesOpen[i]?.teamSize,
                            allMatchesOpen[i]?.coins
                          )
                        "
                      >
                        <div class="d-flex justify-content-between">
                          <div class="d-flex align-items-center gap-2">
                            <img
                              class="rounded"
                              src="../../../assets/img/Fortnite_icon.webp"
                              loading="lazy"
                              alt=""
                              width="64"
                              height="64"
                            />
                            <div class="d-flex flex-column align-items-start">
                              <span>{{ allMatchesOpen[i]?.teamSize }}</span>
                              <mat-chip-listbox aria-label="Dog selection">
                                <mat-chip>
                                  {{ allMatchesOpen[i]?.mode }}
                                </mat-chip>
                                <mat-chip color="primary">
                                  {{ "MATCH.BESTOF.TITLE" | translate }}
                                  {{ allMatchesOpen[i]?.bestOf }}
                                </mat-chip>
                              </mat-chip-listbox>
                            </div>
                          </div>
                          <div>
                            <img
                              src="assets/img/Coin.svg"
                              alt="BeatTheAura"
                              width="32px"
                              height="32px"
                              class="mw-100 rounded-5"
                              style="aspect-ratio: 1/1"
                            />
                            {{ allMatchesOpen[i]?.coins }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="buttonClicked === 'ongoing'" class="w-100">
          <div class="w-100">
            <div
              class="d-flex justify-content-center align-items-center flex-wrap"
            >
              <div class="w-100">
                <div
                  *ngIf="!(allMatchesOnGoing && allMatchesOnGoing.length > 0)"
                  class="d-flex justify-content-between align-items-center flex-column"
                >
                  <h1>{{ "MATCHES.NO-ONGOING" | translate }}</h1>
                  <span>{{ "MATCHES.NO-MATCHES.PARAGRAPH" | translate }}</span>
                </div>
                <div *ngIf="allMatchesOnGoing">
                  <div class="css-uewffw">
                    <div *ngFor="let i of cardMatchesIndicesOnGoing">
                      <div
                        class="cardFVB"
                        [routerLink]="[
                          'match',
                          allMatchesOnGoing[i]?._id
                        ]"
                      >
                        <div class="d-flex justify-content-between">
                          <div class="d-flex align-items-center gap-2">
                            <img
                              class="rounded"
                              src="../../../assets/img/Fortnite_icon.webp"
                              loading="lazy"
                              alt=""
                              width="64"
                              height="64"
                            />
                            <div class="d-flex flex-column align-items-start">
                              <span>{{ allMatchesOnGoing[i]?.teamSize }}</span>
                              <mat-chip-listbox aria-label="Dog selection">
                                <mat-chip>
                                  {{ allMatchesOnGoing[i].mode }}
                                </mat-chip>
                                <mat-chip color="primary">
                                  {{ "MATCH.BESTOF.TITLE" | translate }}
                                  {{ allMatchesOnGoing[i]?.bestOf }}
                                </mat-chip>
                              </mat-chip-listbox>
                            </div>
                          </div>
                          <div>
                            <img
                              src="assets/img/Coin.svg"
                              alt="BeatTheAura"
                              width="32px"
                              height="32px"
                              class="mw-100 rounded-5"
                              style="aspect-ratio: 1/1"
                            />
                            {{ allMatchesOnGoing[i]?.coins }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="buttonClicked === 'finished'" class="w-100">
          <div class="w-100">
            <div
              class="d-flex justify-content-center align-items-center flex-wrap"
            >
              <div class="w-100">
                <div
                  *ngIf="!(allMatchesFinished && allMatchesFinished.length > 0)"
                  class="d-flex justify-content-between align-items-center flex-column"
                >
                  <h1>{{ "MATCHES.NO-FINISHED" | translate }}</h1>
                  <span>{{ "MATCHES.NO-MATCHES.PARAGRAPH" | translate }}</span>
                </div>
                <div *ngIf="allMatchesFinished">
                  <div class="css-uewffw">
                    <div *ngFor="let i of cardMatchesIndicesFinished">
                      <div
                        class="cardFVB"
                        [routerLink]="[
                          'match',
                          allMatchesFinished[i]?._id
                        ]"
                      >
                        <div class="d-flex justify-content-between">
                          <div class="d-flex align-items-center gap-2">
                            <img
                              class="rounded"
                              src="../../../assets/img/Fortnite_icon.webp"
                              loading="lazy"
                              alt=""
                              width="64"
                              height="64"
                            />
                            <div class="d-flex flex-column align-items-start">
                              <span>{{ allMatchesFinished[i]?.teamSize }}</span>
                              <mat-chip-listbox aria-label="Dog selection">
                                <mat-chip>
                                  {{ allMatchesFinished[i].mode }}
                                </mat-chip>
                                <mat-chip color="primary">
                                  {{ "MATCH.BESTOF.TITLE" | translate }}
                                  {{ allMatchesFinished[i]?.bestOf }}
                                </mat-chip>
                              </mat-chip-listbox>
                            </div>
                          </div>
                          <div>
                            <img
                              src="assets/img/Coin.svg"
                              alt="BeatTheAura"
                              width="32px"
                              height="32px"
                              class="mw-100 rounded-5"
                              style="aspect-ratio: 1/1"
                            />
                            {{ allMatchesFinished[i]?.coins }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</dashboard-sidebar>

<div
  class="modal fade"
  id="customize"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <match-card></match-card>
</div>
