import { Component, OnInit } from '@angular/core';
import { User, UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { TeamService } from '../../services/team.service';
import { CustomizeeTeamComponent } from '../../dashboard/dashboard/team-card/team-card.component';
import { WebSocketService } from '../../services/websocket.service';
import { WebSocketEvents, WebSocketMessage } from '../../services/websocket.interface';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileOverviewComponent implements OnInit {

  buttonClicked: 'teams' | 'links' | 'tips' = 'teams';

  teams = [];

  selectedDecorator: string;

  epicId = new FormControl('', Validators.required);
  username = new FormControl('', Validators.required);
  avatar = new FormControl('', Validators.required);
  decorator = new FormControl('', Validators.required);
  coins: number;
  inventory: any;

  get user() { return this.userService.user ?? {}; }
  get savedUser() { return this.userService.savedUser ?? {}; }

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    public apiService: ApiService,
    private userService: UserService,
    private teamService: TeamService,
    private webSocketService: WebSocketService
  ) {
    document.title = 'BeatTheAura - Dashboard';
  }

  ngOnInit() {
    this.webSocketService.subscribeToUpdates(WebSocketEvents.USER_UPDATED, { userId: this.user.id });
    this.webSocketService.listenForUpdates(WebSocketEvents.USER_UPDATED).subscribe({
      next: (message: WebSocketMessage) => {
        if (message.event === WebSocketEvents.USER_UPDATED) {
          this.updateUser(message.data.updatedUser);
        }
      },
      error: (error) => {
        console.error(error);
      }
    });

    this.userService.init().catch(error => {
      console.error(error);
    });

    this.updateForm(this.savedUser);

    this.loadUserTeams();
  }

  updateUser(updatedUser: User) {
    this.avatar.patchValue(updatedUser.avatar);
    this.decorator.patchValue(updatedUser.decorator);
    this.username.patchValue(updatedUser.username);
    this.epicId.patchValue(updatedUser.epicId);
    this.coins = updatedUser.coins;
    this.inventory = updatedUser.inventory;
  }

  updateForm(user) {
    this.epicId.patchValue(user.epicId ?? '');
    this.username.patchValue(user.username ?? '');
    this.avatar.patchValue(user.avatar ?? '');
    this.decorator.patchValue(user.decorator ?? '');
  }

  submitUser() {
    const updatedUserData: User = {
      _id: this.user.id,
      epicId: this.epicId.value,
      username: this.username.value,
      avatar: this.avatar.value,
      decorator: this.decorator.value,
      coins: this.coins,
      inventory: this.inventory
    };

    // Emitir el evento de actualización del usuario a través del WebSocket
    this.webSocketService.emitUserUpdates(WebSocketEvents.USER_UPDATED, this.user.id, { ...updatedUserData });
  }

  loadUserTeams() {
    this.teamService.getUserTeams(this.user.id).then(userTeams => {
      this.teams = userTeams;
    }).catch(error => {
      console.error('Error loading user teams:', error);
    });
  }

  openDialog(teamId: string, teamName: string, teamMembers: string[], earnings: string, played: string, wins: string, losses: string): void {
    const dialogRef = this.dialog.open(CustomizeeTeamComponent, {
      data: {
        teamId, teamName, teamMembers, teamStats: {
          earnings: earnings,
          played: played,
          wins: wins,
          losses: losses,
        }
      },
    });

    dialogRef.afterClosed().subscribe(result => result);
  }
}
