<div class="modal-dialog" role="document">
  <div class="modal-content">
    <form>
      <div class="modal-header">
        <h5 class="modal-title">{{ "USER.ADD" | translate }}</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
        ></button>
      </div>
      <div class="modal-body">
        <div
          class="d-flex flex-column justify-content-between align-items-center gap-2"
        >
          <div *ngIf="!decorator.value">
            <img
              [src]="avatar.value"
              loading="lazy"
              class="img-fluid rounded-circle"
              width="96px"
              alt="..."
            />
          </div>
          <div
            *ngIf="decorator.value"
            class="d-flex align-items-center justify-content-center"
          >
            <img
              class="position-absolute"
              [src]="decorator.value"
              width="148px"
              height="148px"
              alt=" "
              aria-hidden="true"
            />
            <img
              [src]="avatar.value"
              loading="lazy"
              class="img-fluid rounded-circle"
              width="124px"
              height="124px"
              alt="..."
            />
          </div>
          <div class="d-flex flex-column gap-2">
            <div>
              <label>{{ "USER.AVATAR.TITLE" | translate }}</label>
              <input
                class="p-2 w-100 border border-0 rounded text-font bg-tertiary"
                type="text"
                placeholder="BeatTheAura"
                [formControl]="avatar"
              />
              <div *ngIf="avatar.invalid && avatar.errors['required']">
                {{ "USER.AVATAR.TITLE" | translate }}
              </div>
            </div>
            <div>
              <label>{{ "USER.USERNAME.TITLE" | translate }}</label>
              <input
                class="p-2 w-100 border border-0 rounded text-font bg-tertiary"
                type="text"
                placeholder="BeatTheAura"
                [formControl]="username"
              />
              <div *ngIf="username.invalid && username.errors['required']">
                {{ "USER.USERNAME.ERROR" | translate }}
              </div>
            </div>
            <div>
              <label>{{ "USER.EPICGAMES.TITLE" | translate }}</label>
              <input
                class="p-2 w-100 border border-0 rounded text-font bg-tertiary"
                type="text"
                placeholder="BeatTheAura"
                [formControl]="epicId"
              />
              <div *ngIf="epicId.invalid && epicId.errors['required']">
                {{ "USER.EPICGAMES.TITLE" | translate }}
              </div>
            </div>
            <div>
              <label>{{ "USER.DECORATOR.TITLE" | translate }}</label>
              <select
                class="p-2 w-100 border-0 rounded text-font bg-tertiary"
                [formControl]="decorator"
              >
                <option
                  *ngFor="let item of savedUser.inventory"
                  [value]="item.img"
                >
                  {{ item.name }}
                </option>
              </select>
              <div *ngIf="decorator.invalid && decorator.errors['required']">
                {{ "USER.DECORATOR.TITLE" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{ "MODAL.CLOSE" | translate }}
        </button>
        <button
          class="btn btn-bd-primary"
          data-bs-dismiss="modal"
          (click)="submitUser()"
          [disabled]="
            (avatar.invalid && avatar.errors['required']) ||
            (username.invalid && username.errors['required']) ||
            (epicId.invalid && epicId.errors['required'])
          "
        >
          {{ "MODAL.SAVE" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
