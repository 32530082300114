import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WebSocketService } from '../../services/websocket.service';
import { TeamCard, TeamService } from '../../services/team.service';
import { ApiService } from '../../services/api.service';
import { WebSocketEvents, WebSocketMessage } from '../../services/websocket.interface';
import { MatchCard, MatchService } from '../../services/match.service';

export interface DialogData {
  _id: string,
  mode: string,
  bestOf: string,
  teamSize: string,
  coins: number
}

@Component({
  selector: 'matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.css']
})
export class MatchesComponent implements OnInit {

  cardMatchesIndicesOpen = [];
  cardMatchesIndicesOnGoing = [];
  cardMatchesIndicesFinished = [];

  buttonClicked: 'open' | 'ongoing' | 'finished' = 'open';

  allMatches: any[] = [];
  allMatchesOpen: any[] = [];
  allMatchesOnGoing: any[] = [];
  allMatchesFinished: any[] = [];

  get user() { return this.userService.user; }

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    private userService: UserService,
    private router: Router,
    private webSocketService: WebSocketService
  ) { }

  async ngOnInit() {
    try {
      this.subscribeToMatchesUpdates();
    } catch (error) {
      console.error('Error during initialization:', error);
    }
  }

  private subscribeToMatchesUpdates() {
    this.webSocketService.subscribeToUpdates(WebSocketEvents.ALL_MATCHES);
    this.webSocketService.listenForUpdates(WebSocketEvents.ALL_MATCHES).subscribe({
      next: async (message: WebSocketMessage) => {
        if (message.event === WebSocketEvents.ALL_MATCHES) {
          this.processReceivedMatches(message.data);
        }
      },
      error: (error) => {
        console.error('WebSocket error:', error);
      }
    });
  }

  private async processReceivedMatches(matches: any[]) {
    this.allMatches = matches;
    await this.updateMatchLists();
  }

  // Método para actualizar las listas de partidas
  private updateMatchLists() {
    const { open, ongoing, finished } = this.categorizeMatches(this.allMatches);
    this.allMatchesOpen = open;
    this.allMatchesOnGoing = ongoing;
    this.allMatchesFinished = finished;

    this.updateIndices();
  }

  private categorizeMatches(matches: any[]) {
    return matches.reduce((acc, match) => {
      if (match.status === 'open') {
        acc.open.push(match);
      } else if (match.status === 'ongoing') {
        acc.ongoing.push(match);
      } else if (match.status === 'finished') {
        acc.finished.push(match);
      }
      return acc;
    }, { open: [], ongoing: [], finished: [] });
  }

  private updateIndices() {
    this.cardMatchesIndicesOpen = Array.from({ length: this.allMatchesOpen.length }, (_, i) => i);
    this.cardMatchesIndicesOnGoing = Array.from({ length: this.allMatchesOnGoing.length }, (_, i) => i);
    this.cardMatchesIndicesFinished = Array.from({ length: this.allMatchesFinished.length }, (_, i) => i);
  }

  openDialog(_id: string, mode: string, bestOf: string, teamSize: string, coins: number): void {
    const dialogRef = this.dialog.open(DialogOverviewAccept, {
      data: { _id, mode, bestOf, teamSize, coins },
    });

    dialogRef.afterClosed().subscribe(result => result);
  }

  openMatch(_id: string): void {
    this.router.navigate([`matches/match/${_id}`]);
  }
}

@Component({
  selector: 'dialog-overview-accept-dialog',
  templateUrl: 'dialog-overview-accept-dialog.html',
  styleUrls: ['./matches.component.css']
})
export class DialogOverviewAccept implements OnInit {
  teams: TeamCard[] = [];
  selectedTeam: TeamCard;
  teamMember: string[] = [];

  get user() { return this.userService.user; }

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewAccept>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiService: ApiService,
    private userService: UserService,
    private matchService: MatchService,
    private teamService: TeamService,
    private router: Router,
    private webSocketService: WebSocketService
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      await this.loadUserTeams();
    } catch (error) {
      console.error('Error during team loading:', error);
    }
  }

  getFilteredTeams(): TeamCard[] {
    return this.teams.filter(team => team.teamMembers.length === parseInt(this.data.teamSize));
  }

  private async loadUserTeams() {
    this.teams = await this.teamService.getUserTeams(this.user.id);
  }

  async submitAccept(matchId: string): Promise<void> {
    try {
      const match = await this.matchService.getMatch(matchId) as MatchCard;
      this.teamMember = match.teamHost.teamMembers;

      const joinRequestData = {
        matchId: matchId,
        teamJoin: this.selectedTeam,
        status: 'ongoing',
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      };

      // Primero, suscríbete a las actualizaciones de este partido
      this.webSocketService.subscribeToUpdates(WebSocketEvents.MATCH_UPDATED, { matchId: matchId });

      // Envía la solicitud para unirse al partido a través del WebSocket
      this.webSocketService.emitMatchUpdates(WebSocketEvents.MATCH_UPDATED, matchId, joinRequestData);

      // Luego, escucha las actualizaciones del partido
      this.webSocketService.listenForUpdates(WebSocketEvents.MATCH_UPDATED).subscribe({
        next: async (message: WebSocketMessage) => {
          console.log(message);
          if (message.data._id === matchId) {
            await this.handleMatchJoin(match);
          }
        }
      });

    } catch (error) {
      console.error('Error accepting match:', error);
    }
  }

  private async handleMatchJoin(match: MatchCard) {
    try {
      const TeamHostUsers = await this.fetchTeamUsers(this.teamMember);
      const TeamJoinUsers = await this.fetchTeamUsers(this.selectedTeam.teamMembers);

      const coinsToSubtract = match.coins as number;
      const hasEnoughCoins = TeamJoinUsers.every(user => user.savedUser.coins >= coinsToSubtract);

      if (!hasEnoughCoins) {
        alert("Error: Not all members of the enemy team have enough coins to accept the challenge");
        return;
      }
      // Actualiza los coins de los usuarios
      await Promise.all([
        ...TeamHostUsers.map(async user => {
          this.apiService.updateUserCoins(user.savedUser._id, user.savedUser.coins - coinsToSubtract)
        }),
        ...TeamJoinUsers.map(async user => {
          this.apiService.updateUserCoins(user.savedUser._id, user.savedUser.coins - coinsToSubtract)
        }),
      ]);

      this.router.navigate([`matches/match/${match._id}`]).then(() => {
        this.dialogRef.close(); // Cierra el diálogo después de la navegación
      });
    } catch (error) {
      console.error('Error in handleMatchJoin:', error);
      throw error;  // Re-lanzamos el error para que sea capturado en submitAccept
    }
  }

  private async fetchTeamUsers(teamMembers: string[]) {
    return Promise.all(teamMembers.map(async userId => {
      const user = await this.apiService.getUser(userId);
      return user;
    }));
  }
}