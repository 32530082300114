<div class="p-2">
  <h1>Invite</h1>
  <hr>
  <div>
    <input type="text" placeholder="Search for friends to invite" [formControl]="searchQuery" (input)="searchUsers()">
  </div>
  <div *ngFor="let user of foundUsers">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center gap-2">
        <img [src]="user.avatar" class="rounded-circle" width="32" height="32" alt="Avatar">
        <span>{{ user.username }}</span>
      </div>
      <button class="btn btn-primary" (click)="addUserToTeam(user._id)">Add</button>
    </div>
  </div>
</div>