import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User, UserService } from '../../../services/user.service';
import { ShopService } from '../../../services/shop.service';

@Component({
  selector: 'app-shop-card',
  templateUrl: './shop-card.component.html',
  styleUrls: ['./shop-card.component.css']
})
export class ShopCardComponent {
  @Input() url: string;
  @Input() price: number;
  @Input() name: string;
  @Output() checkout = new EventEmitter();
  User: User = this.userService.savedUser;

  constructor(
    public translate: TranslateService,
    public userService: UserService
  ) { }

  itemInInventory(): boolean {
    return this.User?.inventory?.some(item => item.name === this.name) ?? false;
  }
}
