<body>
  <div class="noise"></div>
  <div class="overlay"></div>
  <div class="terminal">
    <h1>Error <span class="errorcode">404</span></h1>
    <p class="output">{{ "NOT-FOUND.PARAGRAPH.1" | translate}}
    </p>
    <p class="output">
      {{ "NOT-FOUND.PARAGRAPH.2" | translate}} <a href="/">{{ "NOT-FOUND.BUTTON" | translate}}</a>
    </p>
    <p class="output">{{ "NOT-FOUND.PARAGRAPH.3" | translate}}</p>
  </div>
</body>
