import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  endpoint = environment.endpoint + '/user';
  private _user: any;
  get user() { return this._user; }

  private _savedUser: any;
  get savedUser() {
    return this._savedUser;
  }

  constructor(private http: HttpClient) { }

  private get key() {
    return localStorage.getItem('key');
  }

  async init() {
    if (!this.user || !this.savedUser)
      await this.updateUser();
  }

  async updateUser() {
    this._user = (this.key) ?
      await firstValueFrom(this.http.get(`${this.endpoint}?key=${this.key}`)) : null
    this._savedUser = (this.key) ?
      await firstValueFrom(this.http.get(`${this.endpoint}/saved?key=${this.key}`)) : null;
  }

  async updateUserProfile(userProfile: { _id:string, epicId?: string, username?: string, avatar?: string, decorator?: string }) {
    return await firstValueFrom(this.http.put(`${this.endpoint}/profile?key=${this.key}`, userProfile));
  }

  async updateCoins(coins: number) {
    return await firstValueFrom(this.http.put(`${this.endpoint}/coins?key=${this.key}`, { coins }));
  }

  async sendWithdraw(Withdraw: { coins: number, email: string, user_id: string }[]) {
    return await firstValueFrom(this.http.post(`${this.endpoint}/withdraw`, { Withdraw }));
  }
}

export interface WebSocketUser {
  event: string;
  updatedUser: User;
}

export interface Inventory {
  img: string;
  name: string;
}

export interface User {
  _id: string;
  coins?: number;
  epicId?: string;
  username?: string;
  avatar?: string;
  decorator?: string;
  inventory?: Inventory[];
}