import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { generateRandomNumericCode } from '../../../utils';
import { TeamCard, TeamService } from '../../../services/team.service';

@Component({
  selector: 'create-team-card',
  templateUrl: './create-team-card.component.html',
  styleUrls: ['./create-team-card.component.css']
})
export class CustomizeTeamCreateComponent implements OnInit {

  form = new FormGroup({
    _id: new FormControl(`${generateRandomNumericCode(18)}`),
    name: new FormControl('', [Validators.required]),
    teamMembers: new FormControl<string[]>([], [Validators.required]),
    stats: new FormControl<Object>(
      {
        earnings: new FormControl(0),
        played: new FormControl(0),
        wins: new FormControl(0),
        losses: new FormControl(0),
      }
    ),
  });

  get user() { return this.userService.user ?? {}; }

  constructor(
    public translate: TranslateService,
    private userService: UserService,
    private teamService: TeamService,
  ) {}

  async ngOnInit() {
    this.form.patchValue({ name: `Team ${this.user.username}` });
    this.form.controls.teamMembers.setValue([this.user.id]);
  }

  async createTeam() {
    try {
      const teamCard: TeamCard = {
        _id: this.form.get('_id').value,
        name: this.form.get('name').value,
        teamMembers: [this.user.id],
        stats: {
          earnings: 0,
          played: 0,
          wins: 0,
          losses: 0,
        }
      };

      this.teamService.sendTeamCard(teamCard);

      window.location.reload();
    } catch (error) {
      console.error('Error creating team:', error);
    }
  }
}